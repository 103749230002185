(function () {
    'use strict';

    angular.module('app.controllers')
        .controller('AppCtrl', AppCtrl);

    AppCtrl.$inject = ['$rootScope', '$scope', '$window', 'LangFactory', '$uibModal', 'toastr', 'Tac', 'Promo', '$cookies', 'IS_TAC', 'IS_PROMO'
        // '$scope', 'LANG', 'PROJECT', 'USER_ID', 'ProjectImageFactory', 'ProjectFactory', 'LangFactory', '$uibModal',
        // '$rootScope', '$window', 'LangService', 'ErrorReportFactory', '$timeout'
    ];

    function AppCtrl($rootScope, $scope, $window, LangFactory, $uibModal, toastr, Tac, Promo, $cookies, IS_TAC, IS_PROMO
        // $scope, LANG, PROJECT, USER_ID, ProjectImageFactory, ProjectFactory, LangFactory, $uibModal,
        // $rootScope, $window, LangService, ErrorReportFactory, $timeout
    ) {
        $scope.initApp = function() {
            if (IS_TAC) openTac();
            if (IS_PROMO) openPromo();

            // Tac.index().then(function(response) {
            //     console.log(response);
            //
            //     if (response && response.status) {
                    // openTac(response);
            //     }
            // });
            //
            // Promo.index().then(function(response) {
            //     console.log(response);
            //
            //     // if (response && response.type !== 'none') {
            //     //     openPromo(response);
            //     // }
            // });
        };

        $scope.dateOptions = {
            startingDay: 1,
            appendToBody: true
        };

        $scope.cookie = false;
        $rootScope.cookie = false;

        $scope.setLang = function(lang) {
            toastr.info('Hamarosan');

			// LangFactory.set(lang).then(function(response) {
            //     $window.location.reload();
			// });
		};

        function openTac() {
            if ($cookies.get('tac')) return;

            $uibModal.open({
                templateUrl: 'tac.html',
                controller: 'TacDialogCtrl',
                controllerAs: 'TacDialogVm',
                backdrop: 'static',
                size: 'md',
                // resolve: {
                //     tac: function() { return tac; }
                // }
            }).result.then(function() {
                $cookies.put('tac', true);
            });
        }

        function openPromo() {
            if ($cookies.get('promo')) return;

            $uibModal.open({
                templateUrl: 'promo.html',
                controller: 'PromoDialogCtrl',
                controllerAs: 'PromoDialogVm',
                backdrop: 'static',
                size: 'md',
                // resolve: {
                //     promo: function() { return promo; }
                // }
            }).result.then(function() {
                $cookies.put('promo', true);
            });
        }

        // $scope.moment = moment;
        // $rootScope.LANG = LANG;
        // $rootScope.USER_ID = USER_ID;
        $scope.regexEmail = /^[a-zA-Z0-9_\.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]{2,}(\.[a-zA-Z0-9-]{2,})?$/;
        $scope.regexPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\!-\/\:-\@\[-\`\{-\~])[0-9a-zA-Z\!-\/\:-\@\[-\`\{-\~]{8,}$/;
        $scope.regexUsername = /^[0-9a-zA-Z]+$/;
        $scope.regexPhone = /^\+?[\d\s\/\-\(\)]+$/;
        $scope.regexFonioId = /^\d{1,5}$/;
        $scope.regexMTId = /^\d{9}$/;

        $scope.toInt = function(id){
            return parseInt(id, 10);
        };

        // $scope.toString = function(id){
        //     return id ? id.toString() : null;
        // };
        //
		// $scope.dateFormat = function(date) {
		// 	return moment(date).format('YYYY-MM-DD');
		// };
        //
		// $scope.isActive = function (strName) {
		// 	return $scope.currentPath === strName;
		// };
        //
        // $scope.getNow = function() {
        //     return moment().second(0).milliseconds(0).toDate();
        // };
        //
		// $scope.errorReport = function() {
        //     ErrorReportFactory.getErrorReportType().then(function(response) {
        //         $uibModal.open({
        //             templateUrl: 'errorReport.html',
        //             controller: 'ErrorReportCtrl',
        //             controllerAs: 'ErrorReportVm',
        //             scope: $scope,
        //             animation: false,
        //             size: 'md',
        //             windowClass: 'error-report-dialog',
        //             resolve: {
        //                 errorTypes: function() { return response.data; }
        //             }
        //         }).result.then(function(form) {
        //             ErrorReportFactory.send(form, form);    // FIXME, WTF?!
        //         });
		// 	});
		// };
        //
		// $scope.settings = function() {
        //     $uibModal.open({
        //         templateUrl: 'userSettings.html',
        //         controller: 'UserSettingsCtrl',
        //         controllerAs: 'UserSettingsVm',
        //         scope: $scope,
        //         animation: false,
        //         size: 'md',
        //         windowClass: 'settings-dialog'
        //     });
		// };
        //
		// $scope.objectLength = function(object) {
		// 	return ((object && Object.keys(object).length > 0) ? true : false);
		// };
        //
        // LangFactory.index().then(function (response) {
        //     $scope.langs = response.data;
        //     if ($scope.PROJECT) {
        //         $scope.otherLangs = _.filter($scope.langs, function(lang) { return lang.signal !== $scope.PROJECT.lang; });
        //     }
        // });
        //
        // /**
        //  * DownloadUrl-t összerakó függvény
        //  * @use $scope.getDownloadUrl(url, [getParameters], [isProjectName]);
        //  *
        //  * Paraméterek (K: kötelező, O: opcionális):
        //  * 1. url (K): a teljes url-nek csak a "törzse"
        //  * 2. getParameters (O): object, ha az url-hez hozzá akarunk csapni GET paramétereket, ezeket egy object-ben soroljuk fel, ahol az index a key, pl. {contents: contents, parent: parent_id}
        //  * 3. isProjectName (O): csak akkor kell megadni a false értéket, ha nem szeretnénk, hogy a _p paramétert belerakja
        //  *
        //  * @author Szabó Zoltán
        //  */
        // $scope.getDownloadUrl = function(url, getParameters, isProjectName) {
        //     var downloadUrl = url + '?time='+(new Date()).getTime();    // így nem cachel az iframe
        //
        //     if( isProjectName !== false ) {
        //         downloadUrl += '&_p=' + PROJECT.short_name;
        //     }
        //
        //     if( getParameters && !$.isEmptyObject(getParameters) ) {
        //         angular.forEach(getParameters, function(parameter, key) {
        //             downloadUrl += '&'+key+'=' + parameter;
        //         });
        //     }
        //
        //     return downloadUrl;
        // };
        //
        // /**
        //  * Fájl letöltő függvény - ELVETVE
        //  * @param  {Object} data A data-t és a fájlnevet tartalmaza object
        //  * @author Szabó Zoltán
        //  */
        // /*$scope.downloadFile = function(data) {
        //     var url = (URL || window.URL || window.webkitURL).createObjectURL(new Blob([data.data], {type: data.charset})),
        //         a = document.createElement('a');
        //
        //     a.href = url;
        //     a.download = data.filename;
        //     a.target = '_blank';
        //     a.click();
        // };*/
        //
        // /**
        //  * Fájl letöltő függvény
        //  * @param  {String} url Letölteni kívánt tartalom linkje
        //  * @author Szabó Zoltán
        //  */
        // $scope.downloadFile = function(url) {
        //     $window.open(url, '_blank');
        // };
        //
        // /**
        //  * Ha a felhasználó nyelvén létezik megjelenítendő szöveg, akkor azt
        //  * adja vissza, ha nem akkor ellenőrzi, a projekt nyelvén van-e megadva, ha azon se, akkor azt a szöveget
        //  * adja vissza, amely megtalálható az objektumban.
        //  *
        //  * @param {Object} textData
        //  * @returns {String}
        //  *
        //  * @author Dési Bálint
        //  */
        // $scope.getLangDisplayText = LangService.getDisplayText;
        //
        // /**
        //  * Feltöltés ablakot megnyitó függvény
        //  * @param  {Object} promise    Feltöltés függvénye
        //  * @param  {Object} restObject [description]
        //  * @param  {Array} files       Kiválasztott file-ok
        //  * @param  {Array} errFiles    Kiválasztott file-ok közül a hibásak
        //  * @return {Object}            ModalInstance
        //  * @author Szabó Zoltán
        //  */
        // $scope.openUploadDialog = function(promise, restObject, files, errFiles) {
        //     return $uibModal.open({
        //         templateUrl: 'uploadDialog.html',
        //         controller: 'UploadDialogCtrl',
        //         controllerAs: 'UploadVm',
        //         size: 'sm',
        //         backdrop: 'static',
        //         resolve: {
        //             promise: function() { return promise; },
        //             restObject: function() { return restObject; },
        //             files: function() { return files; },
        //             errFiles: function() { return errFiles; }
        //         }
        //     });
        // };
        //
        // /**
        //  * Megnézi, hogy üres-e az object
        //  * @param  {Object}  object [description]
        //  * @return {Boolean}        [description]
        //  * @author Szabó Zoltán
        //  */
        // $scope.isEmptyObject = function(object) {
        //     return $.isEmptyObject(object);
        // };
        //
        // /**
        //  * Object ürítése
        //  * @param  {Object} objRef Ürítendő object
        //  * @author Szabó Zoltán
        //  */
        // $scope.emptyObject = function(objRef) {
        //     for (var key in objRef) {
        //         if (objRef.hasOwnProperty(key)) {
        //             delete objRef[key];
        //         }
        //     }
        // };
        //
        // /**
        //  * Változó típus detektálás
        //  * @param  {[type]} variable Változó
        //  * @return {[type]}          Változó típusa
        //  * @author Szabó Zoltán
        //  */
        // $scope.typeof = function(variable) {
        //     return $.type(variable);
        // };
        //
        // $scope.focusConfirmYes = function() {
        //     $timeout(function() {
		// 		angular.element('.modal').find('button:first-child').trigger('focus');
		// 	}, 300);
        // };

    }

})();
