(function () {
    'use strict';

    angular.module('admin.factories')
        .factory('TacAdmin', TacAdmin);

    TacAdmin.$inject = ['APIService', 'BASE_URL'];

    function TacAdmin(APIService, BASE_URL) {
        // return angular.extend(new RestangularService('api/tac'), {
        //     index: function() {
        //         return Restangular.all('api').customGET('tac');
        //     },
        //     save: function(data) {
        //         return Restangular.all('api').customPOST({data: data}, 'tac');
        //     }
        // });

        return new APIService(BASE_URL+'/api/admin/tac');
    }

})();
