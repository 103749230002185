(function () {
    'use strict';

    angular.module('admin.controllers')
        .controller('UsersCtrl', UsersCtrl);

    UsersCtrl.$inject = [
        '$uibModal', 'Admin', 'DTColumnDefBuilder'
    ];

    function UsersCtrl(
        $uibModal, Admin, DTColumnDefBuilder
    ) {
        var vm = this;

        vm.initPage = initPage;
        vm.edit = edit;
        vm.add = add;
        vm.destroy = destroy;
        vm.users = [];

        function initPage() {
            index();
        }

        function index() {
            vm.dtColumnDefs = [
                DTColumnDefBuilder.newColumnDef(0),
                DTColumnDefBuilder.newColumnDef(1),
                DTColumnDefBuilder.newColumnDef(2),
                DTColumnDefBuilder.newColumnDef(3),
                DTColumnDefBuilder.newColumnDef(4).notSortable()
            ];

            Admin.index().then(function(response) {
                vm.users = response;
            });
        }

        function edit(user) {
            modal(user);
        }

        function add() {
            modal({});
        }

        function destroy(user) {
            Admin.destroy(user).then(function(response) {
                vm.users = response;
            });
        }

        function modal(data) {
            $uibModal.open({
                templateUrl: 'usersEdit.html',
                controller: 'UsersEditCtrl',
                controllerAs: 'UsersEditVm',
                resolve: {
                    data: function() { return data; }
                }
            }).result.then(function(user) {
                Admin.save(user).then(function(response) {
                    vm.users = response;
                });
            });
        }

    }

})();
