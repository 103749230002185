(function () {
    'use strict';

    angular.module('filters')
        .filter('firstLetter', firstLetter);

    function firstLetter() {
        return function (input, letter, param) {
            input = input || [];
            var out = [];

            input.forEach(function(item) {
                if (removeAccents(item[param].charAt(0).toLowerCase()) === letter.toLowerCase()) {
                    out.push(item);
                }
            });

            return out;

            function removeAccents(value) {
                return value
                    .replace(/á/g, 'a')
                    .replace(/é/g, 'e')
                    .replace(/í/g, 'i')
                    .replace(/ó/g, 'o')
                    .replace(/ö/g, 'o')
                    .replace(/ő/g, 'o')
                    .replace(/ú/g, 'u')
                    .replace(/ü/g, 'u')
                    .replace(/ű/g, 'u');
            }
        };
    }

})();
