(function () {
    'use strict';

    angular.module('app.factories')
        .factory('OrderDevice', OrderDevice);

    OrderDevice.$inject = ['APIService', 'BASE_URL'];

    function OrderDevice(APIService, BASE_URL) {
        return new APIService(BASE_URL+'/api/order_device');
    }

})();
