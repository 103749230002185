(function () {
    'use strict';

    angular.module('admin.factories')
        .factory('Faq', Faq);

    Faq.$inject = ['APIService', 'BASE_URL'];

    function Faq(APIService, BASE_URL) {

        return new APIService(BASE_URL+'/api/faq');

        /*return angular.extend(Restangular.service('api/user'), {
            getAuth: function() {
                return Restangular.all('api').customGET('user');
            }
        });*/

    }

})();
