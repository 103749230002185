(function () {
    'use strict';

    angular.module('admin.controllers')
        .controller('AdminAppCtrl', AdminAppCtrl);

    AdminAppCtrl.$inject = ['$scope', '$rootScope', 'Profile', 'USER_ID'//, '$window', 'LangFactory'
        // '$scope', 'LANG', 'PROJECT', 'USER_ID', 'ProjectImageFactory', 'ProjectFactory', 'LangFactory', '$uibModal',
        // '$rootScope', '$window', 'LangService', 'ErrorReportFactory', '$timeout'
    ];

    function AdminAppCtrl($scope, $rootScope, Profile, USER_ID//, $window, LangFactory
        // $scope, LANG, PROJECT, USER_ID, ProjectImageFactory, ProjectFactory, LangFactory, $uibModal,
        // $rootScope, $window, LangService, ErrorReportFactory, $timeout
    ) {
        var vm = this;

        vm.initAdmin = initAdmin;

        $rootScope.ckOptions = {
            language: 'hu',
            allowedContent: true,
            entities: false,

            removePlugins: 'resize',

            /** http://ckeditor.com/latest/samples/toolbarconfigurator/index.html#advanced */
            toolbar: [
                { name: 'clipboard', items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
                { name: 'editing', items: [ 'Find', 'Replace', '-', 'SelectAll', '-', 'Scayt' ] },
                { name: 'tools', items: [ 'ShowBlocks', 'Source' ] },
                '/',
                { name: 'styles', items: [ 'Format', 'FontSize' ] },
                { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', '-', 'CopyFormatting', 'RemoveFormat' ] },
                { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock' ] },
                { name: 'links', items: [ 'Link', 'Unlink' ] },
                { name: 'insert', items: [ 'Image', 'Table', 'HorizontalRule', 'SpecialChar' ] },
            ]
        };

        function initAdmin() {
            Profile.show(USER_ID).then(function(response) {
                $rootScope.USER = response;
            });
        }

        // $scope.setLang = function(lang) {
		// 	LangFactory.set(lang).then(function(response) {
        //         console.log(response);
        //         $window.location.reload();
		// 	});
		// };

        $scope.options = {
            language: 'hu',
            allowedContent: true,
            entities: false,

            removePlugins: 'resize',

            /** http://ckeditor.com/latest/samples/toolbarconfigurator/index.html#advanced */
            toolbar: [
                { name: 'clipboard', items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
                { name: 'editing', items: [ 'Find', 'Replace', '-', 'SelectAll', '-', 'Scayt' ] },
                { name: 'tools', items: [ 'ShowBlocks', 'Source' ] },
                '/',
                { name: 'styles', items: [ 'Format', 'FontSize' ] },
                { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', '-', 'CopyFormatting', 'RemoveFormat' ] },
                { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock' ] },
                { name: 'links', items: [ 'Link', 'Unlink' ] },
                { name: 'insert', items: [ 'Image', 'Table', 'HorizontalRule', 'SpecialChar' ] },
            ],

            // toolbarGroups: [
            // 	{ name: 'clipboard',   groups: [ 'clipboard', 'undo' ] },
            // 	{ name: 'editing',     groups: [ 'find', 'selection', 'spellchecker' ] },
            // 	{ name: 'links' },
            // 	{ name: 'insert' },
            // 	{ name: 'forms' },
            // 	// { name: 'tools' },
            // 	{ name: 'document',	   groups: [ 'mode', 'document', 'doctools' ] },
            // 	// { name: 'others' },
            // 	'/',
            // 	{ name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
            // 	{ name: 'paragraph',   groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ] },
            // 	{ name: 'styles' },
            // 	// { name: 'colors' }
            // 	// { name: 'about' }
            // ],

            // Define the toolbar groups as it is a more accessible solution.
            // toolbarGroups: [
            //     {"name":"basicstyles","groups":["basicstyles"]},
            // 	{"name":"links","groups":["links"]},
            // 	{"name":"paragraph","groups":["list","blocks"]},
            // 	{"name":"document","groups":["mode"]},
            // 	{"name":"insert","groups":["insert"]},
            // 	{"name":"styles","groups":["styles"]},
            // 	{"name":"about","groups":["about"]}
            // ],
            // Remove the redundant buttons from toolbar groups defined above.
            // removeButtons: 'Underline,Strike,Subscript,Superscript,Anchor,Styles,Specialchar'
            // removeButtons:
            //     'Save,NewPage,DocProps,Preview,Print,Templates,document,' +
            //     'Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,' +
            //     // 'Scayt,' +
            //     'Subscript,Superscript,' +
            //     'Blockquote,BidiLtr,BidiRtl,Language,' +
            //     'Anchor,' +
            //     'Flash,PageBreak,Iframe,' +
            //     'Styles,Font,' +
            //     'TextColor,BGColor,' +
            //     'Maximize,' +
            //     'About'
        };

        // $scope.moment = moment;
        // $rootScope.LANG = LANG;
        // $rootScope.USER_ID = USER_ID;
        $scope.regexEmail = /^[a-zA-Z0-9_\.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]{2,}(\.[a-zA-Z0-9-]{2,})?$/;
        $scope.regexPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\!-\/\:-\@\[-\`\{-\~])[0-9a-zA-Z\!-\/\:-\@\[-\`\{-\~]{8,}$/;
        $scope.regexPhone = /^\+?[\d\s\/\-\(\)]+$/;
        //
        // $scope.toInt = function(id){
        //     return parseInt(id, 10);
        // };
        //
        // $scope.toString = function(id){
        //     return id ? id.toString() : null;
        // };
        //
		// $scope.dateFormat = function(date) {
		// 	return moment(date).format('YYYY-MM-DD');
		// };
        //
		// $scope.isActive = function (strName) {
		// 	return $scope.currentPath === strName;
		// };
        //
        // $scope.getNow = function() {
        //     return moment().second(0).milliseconds(0).toDate();
        // };
        //
		// $scope.errorReport = function() {
        //     ErrorReportFactory.getErrorReportType().then(function(response) {
        //         $uibModal.open({
        //             templateUrl: 'errorReport.html',
        //             controller: 'ErrorReportCtrl',
        //             controllerAs: 'ErrorReportVm',
        //             scope: $scope,
        //             animation: false,
        //             size: 'md',
        //             windowClass: 'error-report-dialog',
        //             resolve: {
        //                 errorTypes: function() { return response.data; }
        //             }
        //         }).result.then(function(form) {
        //             ErrorReportFactory.send(form, form);    // FIXME, WTF?!
        //         });
		// 	});
		// };
        //
		// $scope.settings = function() {
        //     $uibModal.open({
        //         templateUrl: 'userSettings.html',
        //         controller: 'UserSettingsCtrl',
        //         controllerAs: 'UserSettingsVm',
        //         scope: $scope,
        //         animation: false,
        //         size: 'md',
        //         windowClass: 'settings-dialog'
        //     });
		// };
        //
		// $scope.objectLength = function(object) {
		// 	return ((object && Object.keys(object).length > 0) ? true : false);
		// };
        //
        // LangFactory.index().then(function (response) {
        //     $scope.langs = response.data;
        //     if ($scope.PROJECT) {
        //         $scope.otherLangs = _.filter($scope.langs, function(lang) { return lang.signal !== $scope.PROJECT.lang; });
        //     }
        // });
        //
        // /**
        //  * DownloadUrl-t összerakó függvény
        //  * @use $scope.getDownloadUrl(url, [getParameters], [isProjectName]);
        //  *
        //  * Paraméterek (K: kötelező, O: opcionális):
        //  * 1. url (K): a teljes url-nek csak a "törzse"
        //  * 2. getParameters (O): object, ha az url-hez hozzá akarunk csapni GET paramétereket, ezeket egy object-ben soroljuk fel, ahol az index a key, pl. {contents: contents, parent: parent_id}
        //  * 3. isProjectName (O): csak akkor kell megadni a false értéket, ha nem szeretnénk, hogy a _p paramétert belerakja
        //  *
        //  * @author Szabó Zoltán
        //  */
        // $scope.getDownloadUrl = function(url, getParameters, isProjectName) {
        //     var downloadUrl = url + '?time='+(new Date()).getTime();    // így nem cachel az iframe
        //
        //     if( isProjectName !== false ) {
        //         downloadUrl += '&_p=' + PROJECT.short_name;
        //     }
        //
        //     if( getParameters && !$.isEmptyObject(getParameters) ) {
        //         angular.forEach(getParameters, function(parameter, key) {
        //             downloadUrl += '&'+key+'=' + parameter;
        //         });
        //     }
        //
        //     return downloadUrl;
        // };
        //
        // /**
        //  * Fájl letöltő függvény - ELVETVE
        //  * @param  {Object} data A data-t és a fájlnevet tartalmaza object
        //  * @author Szabó Zoltán
        //  */
        // /*$scope.downloadFile = function(data) {
        //     var url = (URL || window.URL || window.webkitURL).createObjectURL(new Blob([data.data], {type: data.charset})),
        //         a = document.createElement('a');
        //
        //     a.href = url;
        //     a.download = data.filename;
        //     a.target = '_blank';
        //     a.click();
        // };*/
        //
        // /**
        //  * Fájl letöltő függvény
        //  * @param  {String} url Letölteni kívánt tartalom linkje
        //  * @author Szabó Zoltán
        //  */
        // $scope.downloadFile = function(url) {
        //     $window.open(url, '_blank');
        // };
        //
        // /**
        //  * Ha a felhasználó nyelvén létezik megjelenítendő szöveg, akkor azt
        //  * adja vissza, ha nem akkor ellenőrzi, a projekt nyelvén van-e megadva, ha azon se, akkor azt a szöveget
        //  * adja vissza, amely megtalálható az objektumban.
        //  *
        //  * @param {Object} textData
        //  * @returns {String}
        //  *
        //  * @author Dési Bálint
        //  */
        // $scope.getLangDisplayText = LangService.getDisplayText;
        //
        // /**
        //  * Feltöltés ablakot megnyitó függvény
        //  * @param  {Object} promise    Feltöltés függvénye
        //  * @param  {Object} restObject [description]
        //  * @param  {Array} files       Kiválasztott file-ok
        //  * @param  {Array} errFiles    Kiválasztott file-ok közül a hibásak
        //  * @return {Object}            ModalInstance
        //  * @author Szabó Zoltán
        //  */
        // $scope.openUploadDialog = function(promise, restObject, files, errFiles) {
        //     return $uibModal.open({
        //         templateUrl: 'uploadDialog.html',
        //         controller: 'UploadDialogCtrl',
        //         controllerAs: 'UploadVm',
        //         size: 'sm',
        //         backdrop: 'static',
        //         resolve: {
        //             promise: function() { return promise; },
        //             restObject: function() { return restObject; },
        //             files: function() { return files; },
        //             errFiles: function() { return errFiles; }
        //         }
        //     });
        // };
        //
        // /**
        //  * Megnézi, hogy üres-e az object
        //  * @param  {Object}  object [description]
        //  * @return {Boolean}        [description]
        //  * @author Szabó Zoltán
        //  */
        // $scope.isEmptyObject = function(object) {
        //     return $.isEmptyObject(object);
        // };
        //
        // /**
        //  * Object ürítése
        //  * @param  {Object} objRef Ürítendő object
        //  * @author Szabó Zoltán
        //  */
        // $scope.emptyObject = function(objRef) {
        //     for (var key in objRef) {
        //         if (objRef.hasOwnProperty(key)) {
        //             delete objRef[key];
        //         }
        //     }
        // };
        //
        // /**
        //  * Változó típus detektálás
        //  * @param  {[type]} variable Változó
        //  * @return {[type]}          Változó típusa
        //  * @author Szabó Zoltán
        //  */
        // $scope.typeof = function(variable) {
        //     return $.type(variable);
        // };
        //
        // $scope.focusConfirmYes = function() {
        //     $timeout(function() {
		// 		angular.element('.modal').find('button:first-child').trigger('focus');
		// 	}, 300);
        // };

    }

})();
