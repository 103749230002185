(function() {
    'use strict';

    // Global
    angular.module('directives', []);
    angular.module('filters', []);
    angular.module('services', []);

    // App
    angular.module('app.controllers', []);
    angular.module('app.factories', []);
    angular.module('app.vendor', [
        'ngAnimate',
        'ngSanitize',
        'ui.bootstrap',
        'angular-loading-bar',
        'ngMessages',
        'ngCookies',
        'vcRecaptcha',
        // 'ckeditor',
        'restangular',
        'ngclipboard',
        'toastr',
        // 'angular-confirm',
        'datatables',
        'ui.select',
        'CookieComponent'
    ]);
    angular.module('app', [
        'app.controllers',
        'directives',
        'filters',
        'services',
        'app.factories',
        'app.vendor'
    ]);

    // Admin
    angular.module('admin.controllers', []);
    angular.module('admin.factories', []);
    angular.module('admin.vendor', [
        'ngAnimate',
        'ui.bootstrap',
        'angular-loading-bar',
        'ngMessages',
        'ckeditor',
        'restangular',
        'restmod',
        'ngclipboard',
        'toastr',
        'angular-confirm',
        'datatables',
        'ngFileUpload',
        'ngImgCrop',
        'ckeditor',
        'angularjs-dropdown-multiselect',
        'ngTagsInput',
    ]);
    angular.module('admin', [
        'admin.controllers',
        'directives',
        'filters',
        'services',
        'admin.factories',
        'admin.vendor'
    ]);

})();
