(function () {
    'use strict';

    angular.module('app.factories')
        .factory('Faq', Faq);

    Faq.$inject = ['Restangular', 'BASE_URL'];

    function Faq(Restangular, BASE_URL) {

        return Restangular.service('faq');

//        return {
//
//			/**
//             * Get list of model
//             * @return {array}
//             */
//            index: function () {
//                var deferred = $q.defer(),
//                    obj = {
//                        status: 'ERROR'
//                    };
//
//				$http.get(BASE_URL+'/api/v1/lang').success(function(response) {
//                    obj.status = 'OK';
//                    obj.data = response.data;
//                    deferred.resolve(obj);
//                })
//                .error(function (response) {
//                    obj.data = response;
//                    deferred.reject(obj);
//                });
//
//                return deferred.promise;
//            },
//
//			set: function (lang) {
//                var deferred = $q.defer(),
//                    obj = {
//                        status: 'ERROR'
//                    };
//
//				$http.post(BASE_URL+'/api/lang', {'lang': lang}).then(function(response) {
//                    obj.status = 'OK';
//                    obj.data = response.data;
//                    deferred.resolve(obj);
//                })
//                .catch(function (response) {
//                    // obj.data = response;
//                    // deferred.reject(obj);
//                });
//
//                return deferred.promise;
//            }
//        };
    }

})();
