(function () {
    'use strict';

    angular.module('admin.controllers')
        .controller('ProfileCtrl', ProfileCtrl);

    ProfileCtrl.$inject = [
        '$rootScope', 'Profile', 'USER_ID'
    ];

    function ProfileCtrl(
        $rootScope, Profile, USER_ID
    ) {
        var vm = this;

        vm.profile = null;
        vm.initPage = initPage;
        vm.saveBasic = saveBasic;
        vm.saveImage = saveImage;
        vm.profileImage = null;

        function initPage() {
            Profile.show(USER_ID).then(function(response) {
                vm.profile = response;
            });
        }

        function saveBasic() {
            Profile.save(vm.profile).then(function(response) {
                vm.BasicProfileForm.$setPristine();

                angular.extend(vm.profile, response);
                angular.extend($rootScope.USER, response);
            });
        }

        function saveImage() {
            Profile.uploadImage(vm.profile, vm.croppedImgUrl, vm.profileImage).then(function(response) {
                vm.profileImage = null;
                vm.croppedImgUrl = null;
                vm.ImageForm.$setPristine();

                angular.extend(vm.profile, {
                    image: response.data.data.image,
                    image_url: response.data.data.image_url + '?' + new Date().getTime()
                });
                angular.extend($rootScope.USER, {
                    image: response.data.data.image,
                    image_url: response.data.data.image_url + '?' + new Date().getTime()
                });
            });
        }

    }

})();
