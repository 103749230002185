(function () {
    'use strict';

    angular.module('admin.factories')
        .factory('Profile', Profile);

    Profile.$inject = ['RestangularService', 'Upload', '$q', 'APIService', 'BASE_URL'];

    function Profile(RestangularService, Upload, $q, APIService, BASE_URL) {
        // return new APIService('/api/user');

        APIService.prototype.uploadImage = function(user, croppedImgUrl, image) {
            var deferred = $q.defer();

            if (image) {
                Upload.upload({
                    url: BASE_URL + '/api/user/' + user.id,                                 // TODO Base-Url
                    data: {file: Upload.dataUrltoBlob(croppedImgUrl, image.name)}
                }).then(function(resp) {
                    // console.log('Success ' + resp.config.data.file.name + 'uploaded. Response: ' + resp.data);
                    deferred.resolve(resp);
                }, function(resp) {
                    // console.log('Error status: ' + resp.status);
                }, function(evt) {
                    // var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    // console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
                });
            }

            return deferred.promise;
        };

        return new APIService(BASE_URL+'/api/user');
    }

})();
