(function () {
    'use strict';

    angular.module('app.controllers')
        .controller('IndexCtrl', IndexCtrl);

    IndexCtrl.$inject = [
        '$uibModal'
    ];

    function IndexCtrl(
        $uibModal
    ) {
        var vm = this;

        vm.initPage = initPage;
        vm.modal = modal;

        function initPage() {
            $('.index-content *').hyphenate('hu');
        }

        function modal(template) {
            $uibModal.open({
                templateUrl: template + '.html',
                controller: 'IndexDialogCtrl',
                controllerAs: 'IndexDialogVm',
                size: 'lg'
            });
        }

    }

})();
