(function () {
    'use strict';

    angular.module('admin.factories')
        .factory('Stock', Stock);

    Stock.$inject = ['APIService', 'BASE_URL'];

    function Stock(APIService, BASE_URL) {
        return new APIService(BASE_URL+'/api/stock');
    }

})();
