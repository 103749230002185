// (function () {
//     'use strict';
//
//     angular.module('spp')
//         .config(routeConfig)
//         .run(routeRun);
//
//     routeConfig.$inject = ['$httpProvider', '$routeProvider', '$locationProvider', 'IS_HOME'];
//     routeRun.$inject = ['$rootScope'];
//
//     function routeConfig($httpProvider, $routeProvider, $locationProvider, IS_HOME) {
//         $httpProvider.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
//
//         if (IS_HOME) {
//             return;
//         }
//
//         $locationProvider.html5Mode(true);
//
//         angular.element('.module-tabs').children().each(function(idx, tab) {
//             var url = angular.element(tab).data('url');
//
//             if (idx === 0) {
//                 // alapértelmezetten az első elérhető modulra irányít át
//                 $routeProvider.otherwise({
//                     redirectTo: '/'+url
//                 });
//             }
//             $routeProvider.when('/'+url, {
//                 templateUrl: url+'.html'
//             });
//         });
//     }
//
//     function routeRun($rootScope) {
//         $rootScope.$on('$routeChangeSuccess', function(ev, data) {
//             if (data.$$route) {
//                 $rootScope.currentPath = data.$$route.originalPath;
//             }
//         });
//     }
//
// })();
