(function () {
    'use strict';

    angular.module('app.controllers')
        .controller('RegisterCtrl', RegisterCtrl);

    RegisterCtrl.$inject = [
        'AuthService', 'Reg', '$uibModal', 'vcRecaptchaService'
    ];

    function RegisterCtrl(
        AuthService, Reg, $uibModal, vcRecaptchaService
    ) {
        var vm = this;

        vm.initPage = initPage;
        vm.send = send;
        vm.openTac = openTac;
        vm.viewedTac = false;
        vm.copyData = copyData;

        function initPage() {
            vm.register = Reg.create();
        }

        function send() {
            Reg.store(vm.register).then(function() {
                vm.register = Reg.create();
                vm.acceptTac = false;
                vm.viewedTac = false;
                vcRecaptchaService.reload(vm.gRecaptchaId);
                vm.RegForm.$setPristine();
            });
        }

        function copyData(from, to) {
            vm.register[to] = vm.register[from];
        }

        function openTac() {
            $uibModal.open({
                templateUrl: 'registerTac.html',
                controller: 'RegisterTacDialogCtrl',
                controllerAs: 'RegisterTacDialogVm',
                size: 'lg'
            }).result.then(function() {
                vm.viewedTac = true;
                $('.tac-checkbox').removeClass('disabled');
            });
        }

    }

})();
