(function () {
    'use strict';

    angular.module('admin.controllers')
        .controller('TacCtrl', TacCtrl);

    TacCtrl.$inject = [
        'TacAdmin'
    ];

    function TacCtrl(
        TacAdmin
    ) {
        var vm = this;

        vm.initPage = initPage;
        vm.save = save;
        vm.tac = null;

        function initPage() {
            TacAdmin.index().then(function(response) {
                vm.tac = response;
            });
        }

        function save() {
            TacAdmin.save(vm.tac).then(function(response) {
                // vm.tac = response;
            });
        }

    }

})();
