(function () {
    'use strict';

    angular.module('admin.controllers')
        .controller('ContactCtrl', ContactCtrl);

    ContactCtrl.$inject = [
        'ContactFactory'
    ];

    function ContactCtrl(
        ContactFactory
    ) {
        var vm = this;

        vm.initPage = initPage;
        vm.send = send;

        function initPage() {

        }

        function send() {
            ContactFactory.store(vm.contact).then(function() {

            });
        }

    }

})();
