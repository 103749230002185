(function () {
    'use strict';

    angular.module('app')
        .config(appConfig)
        .run(appRun);

    angular.module('admin')
        .config(adminConfig)
        .run(adminRun);

    appConfig.$inject = ['$httpProvider', '$uibModalProvider', 'toastrConfig'];
    appRun.$inject = ['$rootScope', 'DTDefaultOptions', 'DTOptionsBuilder'];

    adminConfig.$inject = ['$httpProvider', '$uibModalProvider', 'toastrConfig', 'RestangularProvider', 'tagsInputConfigProvider'];
    adminRun.$inject = ['$rootScope', '$confirmModalDefaults', 'LANG', 'DTDefaultOptions', 'DTOptionsBuilder'];

    function appConfig($httpProvider, $uibModalProvider, toastrConfig) {
        $httpProvider.interceptors.push('MessageInterceptor');

        $uibModalProvider.options.backdrop = 'static';
        $uibModalProvider.options.size = 'md';

        angular.extend(toastrConfig, {
            maxOpened: 1,
            autoDismiss: true,
            timeOut: 5000
        });
    }

    function appRun($rootScope, DTDefaultOptions, DTOptionsBuilder) {
        $rootScope.dtOptions = { paging: false, searching: false };
        // $rootScope.dtOptionsWeb = DTOptionsBuilder.newOptions().withOption('searching', false);
        DTDefaultOptions.setDisplayLength(10).setLanguage({
            "emptyTable":     "Nincs rendelkezésre álló adat",
            "info":           "Találatok: _START_ - _END_ Összesen: _TOTAL_",
            "infoEmpty":      "Nulla találat",
            "infoFiltered":   "(_MAX_ összes rekord közül szűrve)",
            "infoPostFix":    "",
            "infoThousands":  " ",
            "lengthMenu":     "_MENU_ találat oldalanként",
            "loadingRecords": "Betöltés...",
            "processing":     "Feldolgozás...",
            "search":         "Keresés:",
            "zeroRecords":    "Nincs a keresésnek megfelelő találat",
            "paginate": {
                "first":    "Első",
                "previous": "Előző",
                "next":     "Következő",
                "last":     "Utolsó"
            },
            "aria": {
                "sortAscending":  ": aktiválja a növekvő rendezéshez",
                "sortDescending": ": aktiválja a csökkenő rendezéshez"
            }
        });

        // $.fn.dataTable.ext.search.push(
        //     function(settings, data, dataIndex) {
        //         if (! $rootScope.alphaFilter) {
        //             return true;
        //         }
        //
        //         if (searchData[0].charAt(0) === $rootScope.alphaFilter) {
        //             return true;
        //         }
        //
        //         return false;
        //     }
        // );
    }

    function adminConfig($httpProvider, $uibModalProvider, toastrConfig, RestangularProvider, tagsInputConfigProvider) {
        $httpProvider.interceptors.push('MessageInterceptor');

        $uibModalProvider.options.backdrop = 'static';
        $uibModalProvider.options.size = 'md';

        angular.extend(toastrConfig, {
            maxOpened: 1,
            autoDismiss: true,
            timeOut: 5000
        });

        tagsInputConfigProvider.setDefaults('tagsInput', {
            placeholder: 'Címzett hozzáadása',
            addOnSpace: true,
            type: 'email',
            allowedTagsPattern: /^[a-zA-Z0-9_\.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]{2,}(\.[a-zA-Z0-9-]{2,})?$/
        });

        RestangularProvider.setResponseExtractor(function(response, operation) {
            return response.data;
        });
    }

    function adminRun($rootScope, $confirmModalDefaults, LANG, DTDefaultOptions, DTOptionsBuilder) {
        $confirmModalDefaults.defaultLabels.title = 'Megerősítés';
        $confirmModalDefaults.defaultLabels.ok = 'Igen';
        $confirmModalDefaults.defaultLabels.cancel = 'Nem';
        $confirmModalDefaults.size = 'sm';
        $confirmModalDefaults.backdrop = 'static';

        $rootScope.LANG = LANG;

        $rootScope.dtOptions = DTOptionsBuilder.newOptions().withPaginationType('full_numbers');
        $rootScope.dtOptionsWeb = function() {};
        // $rootScope.dtOptionsWeb = { paging: false, searching: false };
        // $rootScope.dtOptionsWeb = DTOptionsBuilder.newOptions().withOption('searching', false);
        DTDefaultOptions.setDisplayLength(10).setLanguage({
            "emptyTable":     "Nincs rendelkezésre álló adat",
            "info":           "Találatok: _START_ - _END_ Összesen: _TOTAL_",
            "infoEmpty":      "Nulla találat",
            "infoFiltered":   "(_MAX_ összes rekord közül szűrve)",
            "infoPostFix":    "",
            "infoThousands":  " ",
            "lengthMenu":     "_MENU_ találat oldalanként",
            "loadingRecords": "Betöltés...",
            "processing":     "Feldolgozás...",
            "search":         "Keresés:",
            "zeroRecords":    "Nincs a keresésnek megfelelő találat",
            "paginate": {
                "first":    "Első",
                "previous": "Előző",
                "next":     "Következő",
                "last":     "Utolsó"
            },
            "aria": {
                "sortAscending":  ": aktiválja a növekvő rendezéshez",
                "sortDescending": ": aktiválja a csökkenő rendezéshez"
            }
        });
    }

//    angular.module('admin')
////        .run(sppRun)
//        .config(sppConfig);
//
////    angular.module('spp.controllers')
////        .run(sppControllersRun)
////        .config(sppControllersConfig);
////
////    angular.module('ngTable')
////        .run(ngTableRun);
////
////    sppRun.$inject = ['$rootScope', '$confirmModalDefaults', 'LABEL', 'Upload'];
//    sppConfig.$inject = [
//        /*'BASE_URL', '$httpProvider', */'RestangularProvider'/*, 'unsavedWarningsConfigProvider',
//        'dynamicNumberStrategyProvider', 'toastrConfig', 'LABEL', '$uibTooltipProvider', '$compileProvider'*/
//    ];
////    sppControllersRun.$inject = ['ngTableDefaults'];
////    sppControllersConfig.$inject = ['$animateProvider'];
////    ngTableRun.$inject = ['$templateCache'];
//
////    function sppRun($rootScope, $confirmModalDefaults, LABEL, Upload) {
////        $confirmModalDefaults.templateUrl = '/templates/directive/confirm.html?time='+(new Date()).getTime();
////        $confirmModalDefaults.size = 'sm';
////        $confirmModalDefaults.backdrop = 'static';
////
////        $rootScope.LABEL = LABEL;
////        $rootScope.rowDateFormat = ['yyyy. MM. dd.'];
////        $rootScope.strDateFormat = $rootScope.rowDateFormat[0];
////
////        Upload.setDefaults({ngfMaxFiles: 100});
////    }
//
//    function sppConfig(
//        /*BASE_URL, $httpProvider, */RestangularProvider/*, unsavedWarningsConfigProvider,
//        dynamicNumberStrategyProvider, toastrConfig, LABEL, $uibTooltipProvider, $compileProvider*/
//    ) {
//        // Toast üzenetek
////        angular.extend(toastrConfig, {
////            maxOpened: 1,
////            autoDismiss: true,
////            timeOut: 10000,
////            target: $('.module-content').length ? '.module-content' : 'body'
////        });
//
////        RestangularProvider.setBaseUrl(BASE_URL + '/api');
//        RestangularProvider.setBaseUrl('http://www.fonio.local/api');
////        RestangularProvider.setResponseExtractor(function(response, operation) {
////            return response.data;
////        });
//
//        // Dátum stingek Date objectté alakítása
////        RestangularProvider.addResponseInterceptor(function(response) {
////            return convertToDate(angular.copy(response));
////        });
////
////        function convertToDate(obj) {
////            if (moment(obj, moment.ISO_8601, true).isValid()) {
////                obj = moment.utc(obj).toDate();
////            } else if (angular.isObject(obj)) {
////                angular.forEach(obj, function(value, prop) {
////                    if (obj.hasOwnProperty(prop)) {
////                        obj[prop] = convertToDate(value);
////                    }
////                });
////            }
////            return obj;
////        }
//
//        // Date objectek stringre alakítása
////        RestangularProvider.addRequestInterceptor(function(request) {
////            return convertFromDate(angular.copy(request));
////        });
////
////        function convertFromDate(obj) {
////            if (angular.isDate(obj)) {
////                if (obj.getFullYear() !== 1970 && obj.getFullYear() !== 2000) {
////                    obj = new moment(obj).format('YYYY-MM-DD HH:mm:ss');    // jshint ignore:line
////                } else {
////                    obj = new moment(obj).format('HH:mm:ss');               // jshint ignore:line
////                }
////            } else if (angular.isObject(obj)) {
////                angular.forEach(obj, function(value, prop) {
////                    if (obj.hasOwnProperty(prop)) {
////                        obj[prop] = convertFromDate(value);
////                    }
////                });
////            }
////            return obj;
////        }
//    }
//
////    function sppControllersRun(ngTableDefaults) {
////        ngTableDefaults.params.count = 5;
////        ngTableDefaults.settings.counts = [];
////        ngTableDefaults.settings.groupOptions = { isExpanded: true };
////    }
////
////    function sppControllersConfig($animateProvider) {
////        $animateProvider.classNameFilter(/ng-animate-enabled/);		// Hogy csak azok animálódjanak, amiknek kell is
////    }
////
////    function ngTableRun($templateCache) {
////        $templateCache.put('ng-table/filterRow.html', '<tr ng-show="show_filter" class="ng-table-filters"> <th data-title-text="{{$column.titleAlt(this) || $column.title(this)}}" ng-repeat="$column in $columns" ng-if="$column.show(this)" class="filter {{$column.class(this)}}" ng-class="params.settings().filterOptions.filterLayout===\'horizontal\' ? \'filter-horizontal\' : \'\'"> <div ng-repeat="(name, filter) in $column.filter(this)" ng-include="config.getTemplateUrl(filter)" class="filter-cell" ng-class="[getFilterCellCss($column.filter(this), params.settings().filterOptions.filterLayout), $last ? \'last\' : \'\']"> </div> </th> </tr> ');
////    	$templateCache.put('ng-table/filters/number.html', '<input type="number" name="{{name}}" ng-disabled="$filterRow.disabled" ng-model="params.filter()[name]" class="input-filter form-control" placeholder="{{getFilterPlaceholderValue(filter, name)}}"/> ');
////    	$templateCache.put('ng-table/filters/select-multiple.html', '<select ng-options="data.id as data.title for data in $column.data" ng-disabled="$filterRow.disabled" multiple ng-multiple="true" ng-model="params.filter()[name]" class="filter filter-select-multiple form-control" name="{{name}}"> </select> ');
////    	$templateCache.put('ng-table/filters/select.html', '<select ng-options="data.id as data.title for data in $selectData" ng-table-select-filter-ds="$column" ng-disabled="$filterRow.disabled" ng-model="params.filter()[name]" class="filter filter-select form-control" name="{{name}}"> <option style="display:none" value=""></option> </select> ');
////    	$templateCache.put('ng-table/filters/text.html', '<input type="text" name="{{name}}" ng-disabled="$filterRow.disabled" ng-model="params.filter()[name]" class="input-filter form-control" placeholder="{{getFilterPlaceholderValue(filter, name)}}"/> ');
////    	$templateCache.put('ng-table/groupRow.html', '<tr ng-if="params.hasGroup()" ng-show="$groupRow.show" class="ng-table-group-header"> <th colspan="{{getVisibleColumns().length}}" class="sortable" ng-class="{ \'sort-asc\': params.hasGroup($selGroup, \'asc\'), \'sort-desc\':params.hasGroup($selGroup, \'desc\') }"> <a href="" ng-click="isSelectorOpen=!isSelectorOpen" class="ng-table-group-selector"> <strong class="sort-indicator">{{$selGroupTitle}}</strong> <button class="btn btn-default btn-xs ng-table-group-close" ng-click="$groupRow.show=false; $event.preventDefault(); $event.stopPropagation();"> <span class="glyphicon glyphicon-remove"></span> </button> <button class="btn btn-default btn-xs ng-table-group-toggle" ng-click="toggleDetail(); $event.preventDefault(); $event.stopPropagation();"> <span class="glyphicon" ng-class="{ \'glyphicon-resize-small\': params.settings().groupOptions.isExpanded, \'glyphicon-resize-full\': !params.settings().groupOptions.isExpanded }"></span> </button> </a> <div class="list-group" ng-if="isSelectorOpen"> <a href="" class="list-group-item" ng-repeat="group in getGroupables()" ng-click="groupBy(group)"> <strong>{{ getGroupTitle(group)}}</strong> <strong ng-class="isSelectedGroup(group) && \'sort-indicator\'"></strong> </a> </div> </th> </tr> ');
////    	$templateCache.put('ng-table/header.html', '<ng-table-group-row></ng-table-group-row> <ng-table-sorter-row></ng-table-sorter-row> <ng-table-filter-row></ng-table-filter-row> ');
////    	$templateCache.put('ng-table/pager.html', '<div class="ng-cloak ng-table-pager" ng-if="params.data.length"> <div ng-if="params.settings().counts.length" class="ng-table-counts btn-group pull-right"> <button ng-repeat="count in params.settings().counts" type="button" ng-class="{\'active\':params.count()==count}" ng-click="params.count(count)" class="btn btn-default"> <span ng-bind="count"></span> </button> </div> <ul ng-if="pages.length" class="pagination ng-table-pagination"> <li ng-class="{\'disabled\': !page.active && !page.current, \'active\': page.current}" ng-repeat="page in pages" ng-switch="page.type"> <a ng-switch-when="prev" ng-click="params.page(page.number)" href="">&laquo;</a> <a ng-switch-when="first" ng-click="params.page(page.number)" href=""><span ng-bind="page.number"></span></a> <a ng-switch-when="page" ng-click="params.page(page.number)" href=""><span ng-bind="page.number"></span></a> <a ng-switch-when="more" ng-click="params.page(page.number)" href="">&#8230;</a> <a ng-switch-when="last" ng-click="params.page(page.number)" href=""><span ng-bind="page.number"></span></a> <a ng-switch-when="next" ng-click="params.page(page.number)" href="">&raquo;</a> </li> </ul> </div> ');
////    	$templateCache.put('ng-table/sorterRow.html', '<tr class="ng-table-sort-header"> <th title="{{$column.headerTitle(this)}}" ng-repeat="$column in $columns" ng-class="{ \'sortable\': $column.sortable(this), \'sort-asc\': params.sorting()[$column.sortable(this)]==\'asc\', \'sort-desc\': params.sorting()[$column.sortable(this)]==\'desc\' }" ng-click="sortBy($column, $event)" ng-if="$column.show(this)" ng-init="template=$column.headerTemplateURL(this)" class="header {{$column.class(this)}}"> <div ng-if="!template" class="ng-table-header" ng-class="{\'sort-indicator\': params.settings().sortingIndicator==\'div\'}"> <span ng-bind="$column.title(this)" ng-class="{\'sort-indicator\': params.settings().sortingIndicator==\'span\'}"></span> </div> <div ng-if="template" ng-include="template"></div> </th> </tr> ');
////    }
////
////	// Default configuration for modals
////	$('.modal').modal({
////		backdrop: 'static',
////		keyboard: false,
////		show: false
////	});
////
////    // Auto focus when shown
////    $(document).on('shown.bs.modal', '.modal', function() {
////        $(this).find('[autofocus]').focus();
////    });
////
////	Date.prototype.yyyymmdd = function() {
////		// http://stackoverflow.com/a/3067896/4420833
////		var yyyy = this.getFullYear().toString();
////		var mm = (this.getMonth()+1).toString(); // getMonth() is zero-based
////		var dd  = this.getDate().toString();
////		return yyyy + "-" + (mm.charAt(1)?mm:"0"+mm.charAt(0)) + "-" + (dd.charAt(1)?dd:"0"+dd.charAt(0)); // padding
////	};
////
////	$('.nav-tabs a').click(function (e) {
////		e.preventDefault();
////		$(this).tab('show');
////	});

})();
