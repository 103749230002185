(function () {
    'use strict';

    angular.module('admin.controllers')
        .controller('PromoEditCtrl', PromoEditCtrl);

    PromoEditCtrl.$inject = [
        '$uibModalInstance'
    ];

    function PromoEditCtrl(
        $uibModalInstance
    ) {
        var vm = this;

        vm.initPage = initPage;
        vm.ok = ok;
        vm.cancel = cancel;

        function initPage() {
            // vm.data = Restangular.copy(data);
        }

        function ok() {
            $uibModalInstance.close(vm.data);
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

    }

})();
