(function () {
    'use strict';

    angular.module('app.factories')
        .factory('UserNetfone', UserNetfone);

    UserNetfone.$inject = ['APIService', '$http', '$q', 'BASE_URL'];

    function UserNetfone(APIService, $http, $q, BASE_URL) {
        return new APIService(BASE_URL+'/api/user_api/netfone');
    }

})();
