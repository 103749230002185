(function () {
    'use strict';

    angular.module('admin.controllers')
        .controller('ResellersCtrl', ResellersCtrl);

    ResellersCtrl.$inject = [
        '$uibModal', 'Reseller', 'DTColumnDefBuilder'
    ];

    function ResellersCtrl(
        $uibModal, Reseller, DTColumnDefBuilder
    ) {
        var vm = this;

        vm.alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
        vm.filter = 'a';
        vm.initPage = initPage;
        vm.edit = edit;
        vm.add = add;
        vm.destroy = destroy;
        vm.users = [];

        function initPage() {
            index();
        }

        function index() {
            vm.dtColumnDefs = [
                DTColumnDefBuilder.newColumnDef(0),
                DTColumnDefBuilder.newColumnDef(1),
                DTColumnDefBuilder.newColumnDef(2),
                DTColumnDefBuilder.newColumnDef(3)
            ];

            Reseller.index().then(function(response) {
                console.log(response);
                vm.users = response;
            });
        }

        function edit(user) {
            modal(user);
        }

        function add() {
            modal({});
        }

        function destroy(user) {
            Reseller.destroy(user).then(function(response) {
                vm.users = response;
            });
        }

        function modal(data) {
            $uibModal.open({
                templateUrl: 'usersEdit.html',
                controller: 'UsersEditCtrl',
                controllerAs: 'UsersEditVm',
                resolve: {
                    data: function() { return data; }
                }
            }).result.then(function(user) {
                Reseller.save(user).then(function(response) {
                    vm.users = response;
                });
            });
        }

    }

})();
