(function () {
    'use strict';

    angular.module('services')
        .service('AuthService', AuthService);

    AuthService.$inject = ['$http', '$q', 'BASE_URL'];

    function AuthService($http, $q, BASE_URL) {

        this.postLogin = function (credentials) {
            var deferred = $q.defer();
			$http.post(BASE_URL+'/api/v1/login', credentials)
                .success(function(data){
                    deferred.resolve(data);
                })
                .error(function (msg) {
                    deferred.reject(msg);
                });
            return deferred.promise;
        };

        this.postLogout = function () {
            var deferred = $q.defer();
			$http.post(BASE_URL+'/api/v1/logout')
                .success(function(data){
                    deferred.resolve(data);
                })
                .error(function (msg) {
                    deferred.reject(msg);
                });
            return deferred.promise;
        };

		this.forgotPassword = function (credentials) {
			var deferred = $q.defer();
			$http.post(BASE_URL+'/api/v1/password/forgot', credentials)
                .success(function(data){
                    deferred.resolve(data);
                })
                .error(function (msg) {
                    deferred.reject(msg);
                });
            return deferred.promise;
		};

		this.sendPassword = function (credentials) {
			var deferred = $q.defer();
			$http.post(BASE_URL+'/api/v1/password/reset', credentials)
                .success(function(data){
                    deferred.resolve(data);
                })
                .error(function (msg) {
                    deferred.reject(msg);
                });
            return deferred.promise;
		};

		this.loginCheck = function () {
			var deferred = $q.defer();

			$http.get(BASE_URL+'/api/v1/login_check', {ignoreLoadingBar: true, ignoreBackdrop: true})
                .success(function(data) {
                    deferred.resolve(data);
                })
                .error(function (msg) {
                    deferred.reject(msg);
                });
            return deferred.promise;
		};

		this.postExpired = function (credentials) {
			var deferred = $q.defer();
			$http.post(BASE_URL+'/api/v1/password/expired', credentials)
                .success(function(data){
                    deferred.resolve(data);
                })
                .error(function (msg) {
                    deferred.reject(msg);
                });
            return deferred.promise;
		};

        this.postRegistration = function (credentials) {
			var deferred = $q.defer();
			$http.post(BASE_URL+'/api/register', {data: credentials})
                .success(function(data){
                    deferred.resolve(data);
                })
                .error(function (msg) {
                    deferred.reject(msg);
                });
            return deferred.promise;
		};
    }

})();
