(function () {
    'use strict';

    angular.module('app.factories')
        .factory('Promo', Promo);

    Promo.$inject = ['APIService', 'BASE_URL'];

    function Promo(APIService, BASE_URL) {
        return new APIService(BASE_URL+'/api/promo');
    }

})();
