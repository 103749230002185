(function () {
    'use strict';

    angular.module('app.controllers')
        .controller('CallNeedingCtrl', CallNeedingCtrl);

    CallNeedingCtrl.$inject = [
        '$uibModal', 'Print'
    ];

    function CallNeedingCtrl(
        $uibModal, Print
    ) {
        var vm = this;

        vm.addNumber = addNumber;
        vm.isCollapsed = true;
        vm.initPage = initPage;
        vm.data = {
            number: [{number: null, quantity: null, comment: null}],
            date: new Date(),
            id: null,
            name: null,
            zip: null,
            city: null,
            address: null,
            email: null,
        };
        // vm.data = {
        //     number: [
        //         {number: '1/6333-xxx', quantity: 2, comment: null},
        //         {number: '21/2333-xxx', quantity: 1, comment: 'Teszt'},
        //         {number: '22/200-xxx', quantity: 4, comment: null}
        //     ],
        //     date: new Date(),
        //     id: 12345,
        //     name: 'Teszt Teszt',
        //     zip: '1234',
        //     city: 'Teszt',
        //     address: 'Teszt utca 12.',
        //     email: 'teszt@teszt.hu',
        // };
        vm.modal = modal;
        vm.delNumber = delNumber;

        function initPage() {

        }

        /*
        https://codepen.io/kizmeth/pen/AufCm?editors=1010
        https://hello-angularjs.appspot.com/removetablerow
        https://jsfiddle.net/shanidkv/vbft1sc4/
         */
        function addNumber() {
            vm.data.number.push({number: null, quantity: null, comment: null});
        }

        function delNumber(index) {
            vm.data.number.splice(index, 1);
        }

        function modal(template) {
            $uibModal.open({
                templateUrl: template + '.html',
                controller: 'IndexDialogCtrl',
                controllerAs: 'IndexDialogVm',
                size: 'lg'
            });
        }

    }

})();
