(function () {
    'use strict';

    angular.module('services')
        .service('MessageInterceptor', MessageInterceptor);

    MessageInterceptor.$inject = ['$q', '$injector'];

    /**
     * Ha az api hívás válasza tartalmaz message adattagot, akkor azt automatikusan megjeleníti a toastr segítségével
     * @param {type} $q
     * @param {type} $injector
     */
    function MessageInterceptor($q, $injector) {

        return {
            response: function (response) {
                if (response.data.message) {
                    $injector.get('toastr').success(response.data.message);
                }
                return response;
            },
            responseError: function (rejection) {
                if (rejection.data.error.data) {
                    $injector.get('toastr').error(rejection.data.error.data);
                }
                return $q.reject(rejection);
            }
        };

    }

})();
