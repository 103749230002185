(function () {
    'use strict';

    angular.module('app.controllers')
        .controller('BillingHistoryCtrl', BillingHistoryCtrl);

    BillingHistoryCtrl.$inject = [
        '$uibModal', 'UserBillingHistory'
    ];

    function BillingHistoryCtrl(
        $uibModal, UserBillingHistory
    ) {
        var vm = this;

        vm.balanceTop = balanceTop;
        vm.history = [];
        vm.initPage = initPage;

        function initPage() {
            index();
        }

        function index() {
            // vm.dtColumnDefs = [
            //     DTColumnDefBuilder.newColumnDef(0),
            //     DTColumnDefBuilder.newColumnDef(1),
            //     DTColumnDefBuilder.newColumnDef(2),
            //     DTColumnDefBuilder.newColumnDef(3).notSortable()
            // ];

            UserBillingHistory.index().then(function(response) {
                vm.history = response;
            });
        }

        function balanceTop() {
            modal({});
        }

        function modal() {
            $uibModal.open({
                templateUrl: 'balanceTop.html',
                controller: 'BalanceTopCtrl',
                controllerAs: 'BalanceTopVm',
                // size: 'lg',
                resolve: {
                    // data: function() { return data; }
                }
            }).result.then(function(user) {
                // user.save().then(function(response) {
                //     index();
                // });
            });
        }

    }

})();
