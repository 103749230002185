(function () {
    'use strict';

    angular.module('admin.factories')
        .factory('Card', Card);

    Card.$inject = ['APIService', '$http', '$q', 'BASE_URL'];

    function Card(APIService, $http, $q, BASE_URL) {
        var service = new APIService(BASE_URL+'/api/card');

        return angular.extend(service, {
            indexGroup: function(id) {
                var deferred = $q.defer();

                $http.get(BASE_URL+'/api/card_group/' + id + '/card').then(function(response) {
                    deferred.resolve(response.data.data);
                }, function (response) {
                    deferred.reject(response);
                });

                return deferred.promise;
            }
        });
    }

})();
