(function () {
    'use strict';

    angular.module('admin.factories')
        .factory('Regs', Regs);

    Regs.$inject = ['APIService', 'BASE_URL'];

    function Regs(APIService, BASE_URL) {
        return new APIService(BASE_URL+'/api/regs');
    }

})();
