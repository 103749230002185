(function () {
    'use strict';

    angular.module('app.controllers')
        .controller('AddressBookCtrl', AddressBookCtrl);

    AddressBookCtrl.$inject = [
        '$scope', '$rootScope', 'UserPhonebook'
    ];

    function AddressBookCtrl(
        $scope, $rootScope, UserPhonebook
    ) {
        var vm = this;

        vm.addressBook = [];
        vm.alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
        vm.filter = 'a';
        vm.initPage = initPage;

        // var DtInstance, _alphabetSearch;

        // $scope.$on('event:dataTableLoaded', function(event, loadedDT) {
        //     console.log(event);
        //     $('#min, #max').keyup(function() {
        //         loadedDT.DataTable.draw();
        //     });
        // });

        // $scope.dtInstanceCallback = function(dtInstance) {
        //     DtInstance = dtInstance;
        //
        //     // $.fn.dataTable.ext.search.push(
        //     //     function(settings, data, dataIndex) {
        //     //         console.log(_alphabetSearch);
        //     //         if (! _alphabetSearch) {
        //     //             return true;
        //     //         }
        //     //
        //     //         if (searchData[0].charAt(0) === _alphabetSearch) {
        //     //             return true;
        //     //         }
        //     //
        //     //         return false;
        //     //     }
        //     // );
        //     // dtInstance.DataTable.draw();
        // };

        // vm.filterFn = function(letter) {
        //     // _alphabetSearch = letter;
        //
        //     console.log(DtInstance);
        //
        //     $rootScope.alphaFilter = letter;
        //     DtInstance.DataTable.draw();
        // };

        function initPage() {
            UserPhonebook.index().then(function(response) {
                vm.addressBook = response;
            });
        }

    }

})();
