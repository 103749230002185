(function () {
    'use strict';

    angular.module('app.factories')
        .factory('UserBillingHistory', UserBillingHistory);

    UserBillingHistory.$inject = ['APIService', '$http', '$q', 'BASE_URL'];

    function UserBillingHistory(APIService, $http, $q, BASE_URL) {
        return new APIService(BASE_URL+'/api/user_api/billing_history');
    }

})();
