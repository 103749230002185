(function () {
    'use strict';

    angular.module('admin.factories')
        .factory('CardGroup', CardGroup);

    CardGroup.$inject = ['APIService', 'BASE_URL'];

    function CardGroup(APIService, BASE_URL) {
        return new APIService(BASE_URL+'/api/card_group');
    }

})();
