(function () {
    'use strict';

    angular.module('app.controllers')
        .controller('PhonesCtrl', PhonesCtrl);

    PhonesCtrl.$inject = [
        '$uibModal', '$scope'
    ];

    function PhonesCtrl(
        $uibModal, $scope
    ) {
        var vm = this;

        vm.initPage = initPage;
        vm.modal = modal;

        function initPage() {

        }

        function modal() {
            $uibModal.open({
                templateUrl: 'phones_order.html',
                controller: 'PhonesDialogCtrl',
                controllerAs: 'PhonesDialogVm',
                size: 'lg',
                scope: $scope
            });
        }

    }

})();
