(function () {
    'use strict';

    angular.module('app.factories')
        .factory('Contact', Contact);

    Contact.$inject = ['APIService', 'BASE_URL'];

    function Contact(APIService, BASE_URL) {
        return new APIService(BASE_URL + '/api/contact');
    }

})();
