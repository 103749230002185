(function () {
    'use strict';

    angular.module('app.controllers')
        .controller('BalanceTopCtrl', BalanceTopCtrl);

    BalanceTopCtrl.$inject = [
        '$uibModalInstance'
    ];

    function BalanceTopCtrl(
        $uibModalInstance
    ) {
        var vm = this;

        vm.initPage = initPage;
        vm.cancel = cancel;

        function initPage() {
            
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

    }

})();
