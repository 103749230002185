(function () {
    'use strict';

    angular.module('app.factories')
        .factory('Country', Country);

    Country.$inject = ['APIService', 'BASE_URL'];

    function Country(APIService, BASE_URL) {
        return new APIService(BASE_URL+'/api/base/country');
    }

})();
