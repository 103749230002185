(function () {
    'use strict';

    angular.module('admin.controllers')
        .controller('ClientsEditCtrl', ClientsEditCtrl);

    ClientsEditCtrl.$inject = [
        '$uibModalInstance', 'data', 'Client', 'DTOptionsBuilder'
    ];

    function ClientsEditCtrl(
        $uibModalInstance, data, Client, DTOptionsBuilder
    ) {
        var vm = this;

        vm.initPage = initPage;
        vm.alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
        vm.filter = 'a';
        vm.cancel = cancel;

        function initPage() {
            vm.dtOptions = DTOptionsBuilder.newOptions().withPaginationType('full_numbers').withOption('order', [3, 'desc']);

            vm.data = data;
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

    }

})();
