(function () {
    'use strict';

    angular.module('admin.controllers')
        .controller('RegsEditCtrl', RegsEditCtrl);

    RegsEditCtrl.$inject = [
        '$uibModalInstance', 'data', 'Restangular'
    ];

    function RegsEditCtrl(
        $uibModalInstance, data, Restangular
    ) {
        var vm = this;

        vm.initPage = initPage;
        vm.ok = ok;
        vm.cancel = cancel;

        function initPage() {
            vm.data = Restangular.copy(data);
        }

        function ok() {
            $uibModalInstance.close(vm.data);
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

    }

})();
