(function () {
    'use strict';

    angular.module('admin.controllers')
        .controller('ClientsCtrl', ClientsCtrl);

    ClientsCtrl.$inject = [
        '$uibModal', 'Client', 'DTColumnDefBuilder', 'DTOptionsBuilder'
    ];

    function ClientsCtrl(
        $uibModal, Client, DTColumnDefBuilder, DTOptionsBuilder
    ) {
        var vm = this;

        vm.alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
        vm.filter = 'a';
        vm.initPage = initPage;
        vm.openReseller = openReseller;
        vm.users = [];

        function initPage() {
            index();
        }

        function index() {
            vm.dtOptions = DTOptionsBuilder.newOptions().withPaginationType('full_numbers').withOption('order', [1, 'asc']);

            vm.dtColumnDefs = [
                DTColumnDefBuilder.newColumnDef(0),
                DTColumnDefBuilder.newColumnDef(1),
                DTColumnDefBuilder.newColumnDef(2),
                DTColumnDefBuilder.newColumnDef(3)
            ];

            Client.index().then(function(response) {
                vm.users = response;
            });
        }

        function openReseller(user) {
            modal(user);
        }

        function modal(data) {
            $uibModal.open({
                templateUrl: 'clientsEdit.html',
                controller: 'ClientsEditCtrl',
                controllerAs: 'ClientsEditVm',
                size: 'lg',
                resolve: {
                    data: function() { return data; }
                }
            });
        }

    }

})();
