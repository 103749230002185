(function () {
    'use strict';

    angular.module('app.controllers')
        .controller('ContactCtrl', ContactCtrl);

    ContactCtrl.$inject = [
        'Contact', 'vcRecaptchaService'
    ];

    function ContactCtrl(
        Contact, vcRecaptchaService
    ) {
        var vm = this;

        vm.contact = {
            name: null,
            email: null,
            subject: null,
            message: null,
            copy: false
        };
        vm.initPage = initPage;
        vm.send = send;

        function initPage() {

        }

        function send() {
            Contact.store(vm.contact).then(function() {
                vm.contact = {
                    name: null,
                    email: null,
                    subject: null,
                    message: null,
                    copy: false
                };
                vcRecaptchaService.reload(vm.gRecaptchaId);
                vm.contactForm.$setPristine();
            });
        }

    }

})();
