(function () {
    'use strict';

    angular.module('app.factories')
        .factory('UserPhonebook', UserPhonebook);

    UserPhonebook.$inject = ['APIService', '$http', '$q', 'BASE_URL'];

    function UserPhonebook(APIService, $http, $q, BASE_URL) {
        return new APIService(BASE_URL+'/api/user_api/phonebook');
    }

})();
