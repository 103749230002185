(function () {
    'use strict';

    angular.module('app.factories')
        .factory('Reg', Reg);

    Reg.$inject = ['APIService', 'BASE_URL'];

    function Reg(APIService, BASE_URL) {
        return new APIService(BASE_URL+'/api/reg', null, {
            fee_package: 'basic',
            name: null,
            zip: null,
            city: null,
            address: null,
            email: null,
            mobile: null,
            phone: null,
            fax: null,
            username: null,
            password: null,
            company_name: null,
            tax_number: null,
            residence_zip: null,
            residence_city: null,
            residence_address: null,
            billing_name: null,
            billing_zip: null,
            billing_city: null,
            billing_address: null,
            type: null,
            birth_name: null,
            birth_city: null,
            birth_date: null,
            mother_birth_name: null,
            account_number: null,
            guardian_name: null,
            guardian_zip: null,
            guardian_city: null,
            guardian_address: null,
            guardian_residence_zip: null,
            guardian_residence_city: null,
            guardian_residence_address: null,
            registration_number: null,
            current_account_number: null,
            comment: null,
        });
    }

})();
