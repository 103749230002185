(function () {
    'use strict';

    angular.module('admin.controllers')
        .controller('CardsCtrl', CardsCtrl);

    CardsCtrl.$inject = [
        '$uibModal', 'CardGroup', 'DTColumnDefBuilder'
    ];

    function CardsCtrl(
        $uibModal, CardGroup, DTColumnDefBuilder
    ) {
        var vm = this;

        vm.alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
        vm.filter = 'a';
        vm.initPage = initPage;
        vm.cardGroups = [];
        vm.openGroup = openGroup;

        function initPage() {
            index();
        }

        function index() {
            vm.dtColumnDefs = [
                DTColumnDefBuilder.newColumnDef(0),
                DTColumnDefBuilder.newColumnDef(1),
                DTColumnDefBuilder.newColumnDef(2),
                DTColumnDefBuilder.newColumnDef(3),
                DTColumnDefBuilder.newColumnDef(4),
                // DTColumnDefBuilder.newColumnDef(5)
            ];

            CardGroup.index().then(function(response) {
                _.forEach(response, function(cardGroup) {
                    cardGroup.used = _.filter(cardGroup.cards, {activated: 1}).length;
                    cardGroup.isExpired = new Date(cardGroup.expires) < new Date();
                });
                vm.cardGroups = response;
                console.log(vm.cardGroups);
            });
        }

        function openGroup(group) {
            modal(group);
        }

        function modal(data) {
            $uibModal.open({
                templateUrl: 'cardGroupEdit.html',
                controller: 'CardGroupEditCtrl',
                controllerAs: 'CardGroupEditVm',
                size: 'lg',
                resolve: {
                    groupCode: function() { return data.groupcode; },
                    cards: function() { return _.filter(data.cards, {activated: 1}); }
                }
            });
        }

    }

})();
