(function () {
    'use strict';

    angular.module('admin.factories')
        .factory('PromoAdmin', PromoAdmin);

    PromoAdmin.$inject = ['APIService', 'Upload', '$q', 'BASE_URL'];

    function PromoAdmin(APIService, Upload, $q, BASE_URL) {
        APIService.prototype.store = function(data) {
            var deferred = $q.defer();

            Upload.upload({
                url: BASE_URL + '/api/admin/promo',                                 // TODO Base-Url
                data: {data: data}
            }).then(function(resp) {
                // console.log('Success ' + resp.config.data.file.name + 'uploaded. Response: ' + resp.data);
                deferred.resolve(resp);
            }, function(resp) {
                // console.log('Error status: ' + resp.status);
            }, function(evt) {
                // var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                // console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
            });

            return deferred.promise;
        };

        return new APIService(BASE_URL + '/api/admin/promo');
    }

})();
