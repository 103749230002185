(function() {
    'use strict';

    /**
     * Többnyelvű multi-input
     * @use <lang-input type="" ng-model="" langs=""></lang-input>
     *
     * Attribútumok (K: kötelező, O: opcionális):
     * 1. lang-input (K): deklarálás
     * 2. type (K): input típusa, lehetséges értékek: input, textarea
     * 3. ng-model (K): adat model (object - key: lang, value: label)
     * example:
     *  $scope.test = {
     *   'HU': 'Test',
     *   'EN': '',
     *   'DE': null
     *  };
     * 4. langs (K): oldalon használt nyelvek listája
     * 5. input-name (O): input neve
     * 6. input-id (O): input id-ja
     * 7. required (O): értéke az a nyelv, amit kötelezővé szeretnénk tenni
     * 8. ng-disabled (O): szerkesztés letiltása
     * 9. typeahead (O): tömb autocomplete-hoz
     * 10. size (O): input és button mérete (xs, sm, md, lg)
     * 11. rows (O): textarea sorainak száma
     * 12. before-text (O): input előtt megjelenő szöveg
     * 13. exclude-lang (O): ezt a nyelvet kizárja belőle
     *
     * @author Szabó Zoltán
     */
	angular.module('directives')
        .directive('langInput', langInput);

    langInput.$inject = ['BASE_URL', 'LANG', '$compile', '$timeout'];

    function langInput(BASE_URL, LANG, $compile, $timeout) {
		return {
			restrict: 'E',
			require: 'ngModel',
			scope: {
				ngModel: '=',
				langs: '=',
                type: '@',
                inputName: '@',
                inputId: '@',
                required: '=',
                disabled: '=ngDisabled',
                typeahead: '=',
                rows: '@',
                beforeText: '@',
                beforeLangModel: '=',
                autofocus: '=',
                excludeLang: '='
			},
			templateUrl: BASE_URL + '/templates/directive/langInput.html?time=' + (new Date()).getTime(),
			link: function($scope, $element, $attrs, ngModel) {

                if ($attrs.autofocus) {
                    $timeout(function() {
                        $element.find('input[type="text"], textarea').focus();
                    });
                }

				$scope.ngModel = $scope.ngModel || {};

                $scope.$watch('ngModel', function(newVal) {
                    if( !$scope.ngModel ) $scope.ngModel = {};      // FIXME $watch langs-ban is van (eredeti helyén)
                    angular.forEach($scope.langs, function(lang){
                        if( !$scope.ngModel[lang.signal] ) {
                            $scope.ngModel[lang.signal] = '';
                        }
                    });

                    $timeout(function(){
                        if( $scope.required && !angular.isUndefined(newVal) && !newVal[$scope.required] ) {
                            ngModel.$setValidity('required', false);
                        } else {
                            ngModel.$setValidity('required', true);
                        }
                    });
                }, true);
                $scope.$watch('required', function() {
                    $timeout(function(){
                        if( $scope.required && !angular.isUndefined($scope.ngModel) && !$scope.ngModel[$scope.required] ) {
                            ngModel.$setValidity('required', false);
                        } else {
                            ngModel.$setValidity('required', true);
                        }
                    });
                }, true);

                // Ha nincs ngModel, vagy nincs tartalma, akkor létrehozzuk üresen
                $scope.$watch('langs', function(newVal){
                    if( !$scope.ngModel ) $scope.ngModel = {};
                    angular.forEach($scope.langs, function(lang){
                        if( !$scope.ngModel[lang.signal] ) {
                            $scope.ngModel[lang.signal] = '';
                        }
                    });
                });

                // ha változik a kiszűrt nyelv értéke és az a jelenlegi választott, akkor átállítjuk az első elérhetőre
                $scope.$watch('excludeLang', function(newVal){
                    if ($scope.currentLang === newVal) {
                        $scope.currentLang = _.find($scope.langs, function(lang) {
                            return lang.signal != newVal;
                        }).signal;
                    }
                });

                var defaultLang = LANG;
                if ($attrs.excludeLang === defaultLang) {
                    defaultLang = _.find($scope.langs, function(lang) {
                        return lang.signal != $attrs.excludeLang;
                    }).signal;
                }

                $scope.pageLang = defaultLang;
				$scope.currentLang = defaultLang;
                $scope.isShowButton = false;

				$scope.setLang = function (signal) {
					$scope.currentLang = signal;
				};

                $scope.inputClass = function() {
                    var rowClass = [];

                    if( $attrs.inputClass ) rowClass.push($attrs.inputClass);
                    if( $attrs.size ) rowClass.push('input-' + $attrs.size);
                    if( $scope.isShowButton ) rowClass.push('textarea-button-border');

                    return rowClass.join(' ');
                };
                $scope.btnClass = function() {
                    var rowClass = [];

                    if( $attrs.size ) rowClass.push('btn-' + $attrs.size);

                    return rowClass.join(' ');
                };

                $scope.clickOnDirective = function($event) {
                    $scope.isShowButton = true;

                    $timeout(function(){
                        $element.find('textarea').focus();
                    });
                };
                $scope.clickOutDirective = function() {
                    $scope.isShowButton = false;
                };

                $scope.switchLang = function() {
                    if ($scope.langs.length > 2) {
                        // ha több mint 2 nyelv van akkor dropdownból választható
                        return;
                    }

                    var nextLang = _.find($scope.langs, function(lang) {
                        return lang.signal !== $scope.currentLang && lang.signal !== $scope.excludeLang;
                    });

                    if (nextLang) {
                        $scope.currentLang = nextLang.signal;
                    }
                };

                $compile($element.contents())($scope);
			}
		};
	}

})();
