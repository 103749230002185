(function () {
    'use strict';

    angular.module('app.controllers')
        .controller('AdaptersCtrl', AdaptersCtrl);

    AdaptersCtrl.$inject = [
        '$uibModal', '$scope'
    ];

    function AdaptersCtrl(
        $uibModal, $scope
    ) {
        var vm = this;

        vm.initPage = initPage;
        vm.modal = modal;

        function initPage() {

        }

        function modal() {
            $uibModal.open({
                templateUrl: 'adapters_order.html',
                controller: 'AdaptersDialogCtrl',
                controllerAs: 'AdaptersDialogVm',
                size: 'lg',
                scope: $scope
            });
        }

    }

})();
