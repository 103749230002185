(function () {
    'use strict';

    angular.module('app.controllers')
        .controller('TacDialogCtrl', TacDialogCtrl);

    TacDialogCtrl.$inject = [
        '$uibModalInstance'/*, 'tac'*/
    ];

    function TacDialogCtrl(
        $uibModalInstance/*, tac*/
    ) {
        var vm = this;

        vm.cancel = cancel;
        // vm.tac = tac;

        function cancel() {
            $uibModalInstance.close();
        }

    }

})();
