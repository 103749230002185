(function () {
    'use strict';

    angular.module('app.controllers')
        .controller('StepByStepCtrl', StepByStepCtrl);

    StepByStepCtrl.$inject = [
        '$uibModal'
    ];

    function StepByStepCtrl(
        $uibModal
    ) {
        var vm = this;

        vm.initPage = initPage;
        vm.modal = modal;

        function initPage() {

        }

        function modal(template) {
            $uibModal.open({
                templateUrl: template + '.html',
                controller: 'IndexDialogCtrl',
                controllerAs: 'IndexDialogVm',
                size: 'lg'
            });
        }

    }

})();
