(function () {
    'use strict';

    angular.module('app.controllers')
        .controller('RegisterTacDialogCtrl', RegisterTacDialogCtrl);

    RegisterTacDialogCtrl.$inject = [
        '$uibModalInstance'
    ];

    function RegisterTacDialogCtrl(
        $uibModalInstance
    ) {
        var vm = this;

        vm.cancel = cancel;

        function cancel() {
            $uibModalInstance.close();
        }

    }

})();
