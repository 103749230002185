(function() {
    'use strict';

    var CookieComponent = angular.module('CookieComponent', ['ui.bootstrap', 'ngCookies']);

    CookieComponent.directive('cookie', ['BASE_URL', function(BASE_URL) {
    	return {
    		require: '^?ngModel',
    		restrict: 'E',
    		templateUrl: BASE_URL + "/templates/directive/cookieBar.html",
    		scope: {color: "@", ngModel: "=", moreText: "@", closeText: "@", bodyText: "@"},
    		controller: 'CookieController',
    		link: function($scope, $element, $attrs, ngModel) {

    		}
    	};
    }]);

    CookieComponent.controller('CookieController', function($scope, $element, $uibModal, $cookies) {
        $scope.show = !$cookies.get('cookieConsent');

    	$scope.openPolicyPanel = function() {
            $uibModal.open({
    			templateUrl: 'policyContent.html',
                size: 'lg',
    			controller: function($scope, $uibModalInstance) {
                	$scope.close = function() {
                		$uibModalInstance.dismiss();
                	};
                }
    		});
    	};

    	$scope.closePanel = function() {
    		$cookies.put('cookieConsent', true);
    		$element.hide();
    	};
    });

})();
