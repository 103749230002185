(function () {
    'use strict';

    angular.module('admin.controllers')
        .controller('CardGroupEditCtrl', CardGroupEditCtrl);

    CardGroupEditCtrl.$inject = [
        '$uibModalInstance', 'groupCode', 'cards', 'Restangular', 'Card', 'DTOptionsBuilder'
    ];

    function CardGroupEditCtrl(
        $uibModalInstance, groupCode, cards, Restangular, Card, DTOptionsBuilder
    ) {
        var vm = this;

        vm.initPage = initPage;
        vm.cancel = cancel;

        function initPage() {
            vm.dtOptions = DTOptionsBuilder.newOptions().withPaginationType('full_numbers').withOption('order', [3, 'desc']);

            vm.groupCode = groupCode;
            vm.cards = cards;
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

    }

})();
