(function () {
    'use strict';

    angular.module('app.factories')
        .factory('UserErrorReport', UserErrorReport);

    UserErrorReport.$inject = ['APIService', '$http', '$q', 'BASE_URL'];

    function UserErrorReport(APIService, $http, $q, BASE_URL) {
        return new APIService(BASE_URL+'/api/user_api/error_report');
    }

})();
