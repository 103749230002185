(function () {
    'use strict';

    angular.module('admin.controllers')
        .controller('FaqCtrl', FaqCtrl);

    FaqCtrl.$inject = [
        '$uibModal', 'Faq', 'DTColumnDefBuilder'
    ];

    function FaqCtrl(
        $uibModal, Faq, DTColumnDefBuilder
    ) {
        var vm = this;

        vm.edit = edit;
        vm.add = add;
        vm.faqs = [];
        vm.initPage = initPage;
        vm.remove = remove;

        function initPage() {
            vm.dtColumnDefs = [
                DTColumnDefBuilder.newColumnDef(0),
                DTColumnDefBuilder.newColumnDef(1),
                DTColumnDefBuilder.newColumnDef(2),
                DTColumnDefBuilder.newColumnDef(3).notSortable()
            ];

            Faq.index().then(function(response) {
                vm.faqs = response;
            });
        }

        function edit(user) {
            modal(user);
        }

        function add() {
            modal({});
        }

        function remove(faq) {
            Faq.destroy(faq).then(function(response) {
                vm.faqs = response;
            });
        }

        function modal(data) {
            $uibModal.open({
                templateUrl: 'faqEdit.html',
                controller: 'FaqEditCtrl',
                controllerAs: 'FaqEditVm',
                resolve: {
                    data: function() { return data; }
                }
            }).result.then(function(user) {
                Faq.save(user).then(function(response) {
                    vm.faqs = response;
                });
            });
        }

    }

})();
