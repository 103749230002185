(function () {
    'use strict';

    angular.module('services')
        .service('APIService', APIService);

    APIService.$inject = ['$http', '$q'];

    function APIService($http, $q) {

        /**
         * @desc Model route
         */
        this.route = null;

        /**
         * @desc Nested parent route (optional)
         */
        this.nestedParentRoute = null;

        /**
         * @desc Model default values
         */
        this.model = {};

        /**
         * Construct
         * @param {String} route Model route
         * @param {String} nestedParentRoute Nested parent route (optional)
         * @param {Object} defaultModel Model default values
         */
        function APIService(route, nestedParentRoute, defaultModel) {   // jshint ignore:line
            this.route = route;
            this.nestedParentRoute = nestedParentRoute;
            this.model = defaultModel;
        }

        APIService.prototype.extendModel = function(extendFunction) {
            Restangular.extendModel(this.route, extendFunction);
        };

        APIService.prototype.index = function() {
            var self = this,
                deferred = $q.defer();

            $http.get(self.route).then(function(response) {
                deferred.resolve(response.data.data);
            }, function (response) {
                deferred.reject(response);
            });

            return deferred.promise;
        };

        APIService.prototype.create = function(data, nestedParent) {
            return angular.copy(this.model);
        };

        APIService.prototype.store = function(data) {
            var self = this,
                deferred = $q.defer();

            $http.post(self.route, {data: data}).then(function(response) {
                deferred.resolve(response.data.data);
            }, function (response) {
                deferred.reject(response);
            });

            return deferred.promise;
        };

        APIService.prototype.show = function(id) {
            var self = this,
                deferred = $q.defer();

            $http.get(self.route + '/' + id).then(function(response) {
                deferred.resolve(response.data.data);
            }, function (response) {
                deferred.reject(response);
            });

            return deferred.promise;
        };

        APIService.prototype.update = function(data) {
            var self = this,
                deferred = $q.defer();

            $http.put(self.route + '/' + data.id, {data: data}).then(function(response) {
                deferred.resolve(response.data.data);
            }, function (response) {
                deferred.reject(response);
            });

            return deferred.promise;
        };

        APIService.prototype.destroy = function(data) {
            var self = this,
                deferred = $q.defer();

            $http.delete(self.route + '/' + data.id).then(function(response) {
                deferred.resolve(response.data.data);
            }, function (response) {
                deferred.reject(response);
            });

            return deferred.promise;
        };

        APIService.prototype.save = function(data) {
            return data.id ? this.update(data) : this.store(data);
        };

        return APIService;
    }

})();
