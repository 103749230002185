(function () {
    'use strict';

    angular.module('app.factories')
        .factory('UserCallHistory', UserCallHistory);

    UserCallHistory.$inject = ['APIService', '$http', '$q', 'BASE_URL'];

    function UserCallHistory(APIService, $http, $q, BASE_URL) {
        return new APIService(BASE_URL+'/api/user_api/call_history');
    }

})();
