(function () {
    'use strict';

    angular.module('filters')
        .filter('month', month);

    month.$inject = [];

    function month() {
        return function(num) {
            return ('0' + num).slice(-2);
        };
    }

    angular.module('app.controllers')
        .controller('CallHistoryCtrl', CallHistoryCtrl);

    CallHistoryCtrl.$inject = [
        '$scope', 'UserCallHistory'
    ];

    function CallHistoryCtrl(
        $scope, UserCallHistory
    ) {
        var vm = this,
            date = new Date(),
            prevMonth = new Date(date.setMonth(date.getMonth() - 1)),
            i;

        vm.initPage = initPage;
        vm.history = [];
        vm.allDuration = null;
        vm.filter = {
            start: {
                year: prevMonth.getFullYear(),
                month: prevMonth.getMonth() + 1
            },
            end: {
                year: prevMonth.getFullYear(),
                month: prevMonth.getMonth() + 1
            }
        };
        vm.filterDate = {
            year: [],
            month: []
        };

        $scope.dateOptions = {
            datepickerMode: 'month',
            minMode: 'month',
            maxMode: 'year',
            formatMonth: 'MM'
        };

        function initPage() {
            UserCallHistory.index().then(function(response) {
                vm.history = response.history;
                vm.allDuration = response.allDuration;
                vm.allCost = response.allCost;

                for (i = 2007; i <= date.getFullYear(); i++) {
                    vm.filterDate.year.push(i);
                }
                for (i = 1; i <= 12; i++) {
                    vm.filterDate.month.push(i);
                }

                console.log(vm.filterDate);
            });
        }

    }

})();
