(function () {
    'use strict';

    angular.module('app.factories')
        .factory('Print', Print);

    Print.$inject = ['APIService', 'BASE_URL'];

    function Print(APIService, BASE_URL) {
        return new APIService(BASE_URL+'/api/print/call_needing');
    }

})();
