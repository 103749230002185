(function () {
    'use strict';

    angular.module('app.controllers')
        .controller('UserIndexCtrl', UserIndexCtrl);

    UserIndexCtrl.$inject = [
        '$scope', 'UserIndex', 'Country'
    ];

    function UserIndexCtrl(
        $scope, UserIndex, Country
    ) {
        var vm = this;

        vm.countries = [];
        vm.initPage = initPage;
        vm.user = [];
        vm.ping = null;
        vm.save = save;

        function initPage() {
            UserIndex.index().then(function(response) {
                vm.user = response;
            });
            UserIndex.ping().then(function(response) {
                vm.ping = response;
            });

            Country.index().then(function(response) {
                vm.countries = response;
            });
        }

        function save() {
            UserIndex.update(vm.user).then(function(response) {
                console.log(response);
            });
        }

    }

})();
