(function () {
    'use strict';

    angular.module('app.factories')
        .factory('Tac', Tac);

    Tac.$inject = ['APIService', 'BASE_URL'];

    function Tac(APIService, BASE_URL) {
        return new APIService(BASE_URL + '/api/tac');
    }

})();
