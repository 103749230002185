(function () {
    'use strict';

    angular.module('admin.factories')
        .factory('Admin', Admin);

    Admin.$inject = ['APIService', 'BASE_URL'];

    function Admin(APIService, BASE_URL) {
        // return new RestangularService('api/user', null, {
        //     name: null,
        //     email: null,
        //     username: null,
        //     // password: 'jelszo'
        // });

        return new APIService(BASE_URL+'/api/user');
    }

})();
