(function () {
    'use strict';

    angular.module('admin.controllers')
        .controller('PromoCtrl', PromoCtrl);

    PromoCtrl.$inject = [
        'PromoAdmin'
    ];

    function PromoCtrl(
        PromoAdmin
    ) {
        var vm = this;

        vm.initPage = initPage;
        vm.promo = {
            type: 'image',
            image: null,
            text: null
        };
        vm.save = save;
        vm.saveDisabled = saveDisabled;

        function initPage() {
            PromoAdmin.index().then(function(response) {

                if (response.image === 'null') {
                    response.image = null;
                }
                if (response.text === 'null') {
                    response.text = null;
                }

                vm.currentImage = response.image;
                vm.promo = angular.extend(response, {
                    image: null
                });
            });
        }

        function save() {
            // switch (vm.promo.type) {
            //     case 'image':
            //         vm.promo.text = null;
            //         break;
            //     case 'text':
            //         vm.promo.image = null;
            //         break;
            //     case 'none':
            //         vm.promo.image = null;
            //         vm.promo.text = null;
            //         break;
            // }

            PromoAdmin.store(vm.promo).then(function(response) {
                if (vm.promo.type === 'image') {
                    vm.currentImage = response.data.data.image;     // FIXME data.data
                    vm.promo.image = null;
                }
            });
        }

        function saveDisabled() {
            if (vm.promo.type === 'image') {
                return vm.imageForm.$invalid;
            } else if (vm.promo.type === 'text') {
                return vm.textForm.$invalid;
            }
            return false;
        }

    }

})();
