(function () {
    'use strict';

    angular.module('services')
        .service('RestangularService', RestangularService);

    RestangularService.$inject = ['Restangular'];

    function RestangularService(Restangular) {

        /**
         * @desc Model route
         */
        this.route = null;

        /**
         * @desc Nested parent route (optional)
         */
        this.nestedParentRoute = null;

        /**
         * @desc Model default values
         */
        this.model = {};

        /**
         * Construct
         * @param {String} route Model route
         * @param {String} nestedParentRoute Nested parent route (optional)
         * @param {Object} defaultModel Model default values
         */
        function RestangularService(route, nestedParentRoute, defaultModel) {   // jshint ignore:line
            this.route = route;
            this.nestedParentRoute = nestedParentRoute;
            this.model = defaultModel;
        }

        RestangularService.prototype.extendModel = function(extendFunction) {
            Restangular.extendModel(this.route, extendFunction);
        };

        /**
         * @param {Integer|Object} nestedParent Nested object-ek parent-ja vagy id-ja
         * @param {Object} queryParams Query-nek átadott paraméterek
         * @return {Object} Promise
         */
        RestangularService.prototype.index = function(nestedParent, queryParams) {
            if (this.nestedParentRoute) {
                if (angular.isNumber(nestedParent)) {
                    return Restangular.one(this.nestedParentRoute, nestedParent).all(this.route).getList(queryParams);
                } else {
                    return nestedParent.all(this.route).getList(queryParams);
                }
            } else {
                return Restangular.all(this.route).getList(queryParams);
            }
        };

        /**
         * Új model létrehozása
         * @param {Object} data Object, mellyel a this.model-t lehet kibővíteni
         * @param {Object} nestedParent Nested object parent-ja
         * @return {Object} Restangular-izált "üres" object
         */
        RestangularService.prototype.create = function(data, nestedParent) {
            return Restangular.restangularizeElement(nestedParent || null, angular.extend({}, this.model, data), this.route);
        };

        /**
         * Új model létrehozása
         * @param {Object} data Object, mellyel a this.model-t lehet kibővíteni
         * @param {Object} nestedParent Nested object parent-ja
         * @return {Object} Restangular-izált "üres" object
         */
        RestangularService.prototype.store = function(data, nestedParent) {
            return Restangular.restangularizeElement(nestedParent || null, angular.extend({}, this.model, data), this.route);
        };

        /**
         * @param {Integer} id
         * @param {Integer} nestedParentId Nested object parent-jának id-ja
         * @return {Object} Promise
         */
        RestangularService.prototype.show = function(id, nestedParentId) {
            if (this.nestedParentRoute) {
                return Restangular.one(this.nestedParentRoute, nestedParentId).one(this.route, id).get();
            } else {
                return Restangular.one(this.route, id).get();
            }
        };

        RestangularService.prototype.update = function(data, nestedParent) {
            return Restangular.restangularizeElement(nestedParent || null, angular.extend({}, this.model, data), this.route);
        };

        RestangularService.prototype.destroy = function(data, nestedParent) {
            return Restangular.restangularizeElement(nestedParent || null, angular.extend({}, this.model, data), this.route);
        };

        // TODO FIXME
        RestangularService.prototype.save = function(data) {
            if (data.id) {
                return data.put();
            } else {
                return data.save();
            }
        };

        /**
         * Model klónozása
         * @param {Object} data
         * @return {Object} Model klónja
         */
        RestangularService.prototype.copy = function(data) {
            return Restangular.copy(data);
        };

        /**
         * Új "szülő" objektumot hoz létre (2-nél mélyebben ágyazott route-okat nem kezelünk, ezeket 2 méllyel helyettesítjük)
         * @param {Integer} nestedParentId szülő id
         * @returns {Object} új szülő
         */
        RestangularService.prototype.getNestedParentObject = function(nestedParentId) {
            return {
                id: nestedParentId,
                route: this.nestedParentRoute
            };
        };

        /**
         * Egy elem restangularizálása
         * @param {Object|Integer} parent szülő
         * @param {Object} element elem
         * @returns {Object} restangularizált elem
         */
        RestangularService.prototype.restangularizeElement = function(parent, element) {
            if (angular.isNumber(parent)) {
                parent = this.getNestedParentObject(parent);
            }
            return Restangular.restangularizeElement(parent || null, element, this.route);
        };

        /**
         * Több elem restangularizálása
         * @param {Object|Integer} parent szülő
         * @param {Array} collection elemek
         * @returns {Array} restangularizált elemek
         */
        RestangularService.prototype.restangularizeCollection = function(parent, collection) {
            if (angular.isNumber(parent)) {
                parent = this.getNestedParentObject(parent);
            }
            return Restangular.restangularizeCollection(parent || null, collection, this.route);
        };

        return RestangularService;
    }

})();
