(function () {
    'use strict';

    angular.module('app.controllers')
        .controller('PromoDialogCtrl', PromoDialogCtrl);

    PromoDialogCtrl.$inject = [
        '$uibModalInstance'/*, 'promo'*/
    ];

    function PromoDialogCtrl(
        $uibModalInstance/*, promo*/
    ) {
        var vm = this;

        vm.cancel = cancel;
        // vm.promo = promo;

        function cancel() {
            $uibModalInstance.close();
        }

    }

})();
