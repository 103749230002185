(function () {
    'use strict';

    angular.module('admin.factories')
        .factory('Client', Client);

    Client.$inject = ['APIService', '$http', '$q', 'BASE_URL'];

    function Client(APIService, $http, $q, BASE_URL) {
        var service = new APIService(BASE_URL+'/api/client');

        return angular.extend(service, {
            indexReseller: function() {
                var deferred = $q.defer();

                $http.get(BASE_URL+'/api/resellers').then(function(response) {
                    deferred.resolve(response.data.data);
                }, function (response) {
                    deferred.reject(response);
                });

                return deferred.promise;
            }
        });
    }

})();
