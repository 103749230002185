(function () {
    'use strict';

    angular.module('app.controllers')
        .controller('PhonesDialogCtrl', PhonesDialogCtrl);

    PhonesDialogCtrl.$inject = [
        '$uibModalInstance', 'vcRecaptchaService', 'OrderDevice'
    ];

    function PhonesDialogCtrl(
        $uibModalInstance, vcRecaptchaService, OrderDevice
    ) {
        var vm = this;

        vm.cancel = cancel;
        vm.copyData = copyData;
        vm.ok = ok;
        vm.data = {
            device_id: null,
            quantity: null,
            client_id: null,
            delivery_name: null,
            delivery_zip: null,
            delivery_city: null,
            delivery_address: null,
            mobile: null,
            email: null,
            delivery_type: null,
            billing_name: null,
            billing_zip: null,
            billing_city: null,
            billing_address: null,
            tax: null
        };
        // vm.data = {
        //     device_id: 1,
        //     quantity: 2,
        //     client_id: '12345',
        //     delivery_name: 'Teszt Teszt',
        //     delivery_zip: '1234',
        //     delivery_city: 'Teszt',
        //     delivery_address: 'Teszt utca 12.',
        //     mobile: '123456789',
        //     email: 'teszt@teszt.hu',
        //     delivery_type: 2,
        //     billing_name: 'Teszt Teszt',
        //     billing_zip: '1234',
        //     billing_city: 'Teszt',
        //     billing_address: 'Teszt utca 12.',
        //     tax: null
        // };
        vm.devices = [
            {id: 1, name: 'Grandstream 1400/1405'},
            {id: 2, name: 'Gigaset A540IP'},
            {id: 3, name: 'Grandstream GXP 2000'}
        ];
        vm.deliveryTypes = [
            {id: 1, name: 'Csomagterminál'},
            {id: 2, name: 'Házhozszállítás'},
            {id: 3, name: 'Csomagpont'}
        ];

        function copyData(from, to) {
            vm.data[to] = vm.data[from];
        }

        function ok() {
            OrderDevice.save(angular.extend(vm.data, {type: 'P'})).then(function() {
                $uibModalInstance.close();
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

    }

})();
