(function () {
    'use strict';

    angular.module('admin.controllers')
        .controller('RegsCtrl', RegsCtrl);

    RegsCtrl.$inject = [
        '$uibModal', 'Regs', 'DTColumnDefBuilder', 'DTOptionsBuilder'
    ];

    function RegsCtrl(
        $uibModal, Regs, DTColumnDefBuilder, DTOptionsBuilder
    ) {
        var vm = this;

        vm.edit = edit;
        vm.add = add;
        vm.regs = [];
        vm.initPage = initPage;

        function initPage() {
            vm.dtOptions = DTOptionsBuilder.newOptions().withPaginationType('full_numbers').withOption('order', [4, 'asc']);
            vm.dtColumnDefs = [
                DTColumnDefBuilder.newColumnDef(0),
                DTColumnDefBuilder.newColumnDef(1),
                DTColumnDefBuilder.newColumnDef(2),
                DTColumnDefBuilder.newColumnDef(3),
                DTColumnDefBuilder.newColumnDef(4),
                DTColumnDefBuilder.newColumnDef(5).notSortable()
            ];

            Regs.index().then(function(response) {
                initTables(response);
            });
        }

        function initTables(data) {
            vm.regs = _.filter(data, {'registered_at': null});
            vm.finishedRegs = _.filter(data, function(o) { return o.registered_at; });

            // console.log(vm.regs);
            // console.log(vm.finishedRegs);
        }

        function edit(user) {
            modal(user);
        }

        function add() {
            modal({});
        }

        function modal(data) {
            $uibModal.open({
                templateUrl: 'regsEdit.html',
                controller: 'RegsEditCtrl',
                controllerAs: 'RegsEditVm',
                size: 'lg',
                resolve: {
                    data: function() { return data; }
                }
            }).result.then(function(user) {
                Regs.update(angular.extend(user, {registered_at: moment().format('YYYY-MM-DD HH:mm:ss')})).then(function(response) {
                    initTables(response);
                });
            });
        }

    }

})();
