(function () {
    'use strict';

    angular.module('app.controllers')
        .controller('NumberPortingCtrl', NumberPortingCtrl);

    NumberPortingCtrl.$inject = [
        '$uibModal', 'Print'
    ];

    function NumberPortingCtrl(
        $uibModal, Print
    ) {
        var vm = this;

        vm.addNumber = addNumber;
        vm.delNumber = delNumber;
        vm.initPage = initPage;
        vm.isCollapsed = true;
        vm.data = {
            phoneService: null,
            clientId: null,
            type: null,
            name: null,
            maidenName: null,
            motherName: null,
            zip: null,
            city: null,
            address: null,
            birthPlace: null,
            birthDate: null,
            idNumber: null,
            passportNumber: null,
            companyName: null,
            companyAddress: null,
            companyIdNumber: null,
            contactName: null,
            contactPhone: null,
            contribution: null,
            operatingSpace: {zip: null, city: null, address: null},
            mtId: null,
            carryNumber: [{number: null}],
            date: null
        };
        // vm.data = {
        //     phoneService: 'Teszt Kft.',
        //     clientId: 12345,
        //     type: 'person',
        //     name: 'Teszt Teszt',
        //     maidenName: 'Teszt Teszt',
        //     motherName: 'Teszt Teszt',
        //     zip: '1234',
        //     city: 'Teszt',
        //     address: 'Teszt utca 12.',
        //     birthPlace: 'Teszt',
        //     birthDate: new Date(1990,6,22),
        //     idNumber: 1234,
        //     passportNumber: 12345678,
        //     companyName: 'Teszt Kft.',
        //     companyAddress: '1234 Teszt, Teszt utca 12.',
        //     companyIdNumber: 1234,
        //     contactName: 'Teszt Teszt',
        //     contactPhone: '06123456789',
        //     contribution: 'yes',
        //     operatingSpace: {zip: 1234, city: 'Teszt', address: 'Teszt utca 12.'},
        //     mtId: 123456789,
        //     carryNumber: [{number: '06123456789'}, {number: '06123456789'}/*, {number: '06123456789'}*/],
        //     date: 'Teszt'
        // };
        vm.modal = modal;

        function initPage() {

        }

        function addNumber() {
            vm.data.carryNumber.push({number: null});
        }

        function delNumber(index) {
            vm.data.carryNumber.splice(index, 1);
        }

        function modal(template) {
            $uibModal.open({
                templateUrl: template + '.html',
                controller: 'IndexDialogCtrl',
                controllerAs: 'IndexDialogVm',
                size: 'lg'
            });
        }

    }

})();
