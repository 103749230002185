(function () {
    'use strict';

    angular.module('admin.controllers')
        .controller('StockCtrl', StockCtrl);

    StockCtrl.$inject = [
        '$uibModal', 'Stock', 'DTColumnDefBuilder', 'DTOptionsBuilder'
    ];

    function StockCtrl(
        $uibModal, Stock, DTColumnDefBuilder, DTOptionsBuilder
    ) {
        var vm = this;

        vm.edit = edit;
        vm.add = add;
        vm.stock = {
            phones: [],
            adapters: []
        };
        vm.initPage = initPage;

        function initPage() {
            vm.dtOptions = DTOptionsBuilder.newOptions().withPaginationType('full_numbers').withOption('order', [0, 'asc']);
            vm.dtColumnDefs = [
                DTColumnDefBuilder.newColumnDef(0),
                DTColumnDefBuilder.newColumnDef(1),
                DTColumnDefBuilder.newColumnDef(2),
                DTColumnDefBuilder.newColumnDef(3).notSortable()
            ];

            Stock.index().then(function(response) {
                initTables(response);
            });
        }

        function initTables(data) {
            vm.stock.phones = _.filter(data, function(o) { return o.id <= 3; });
            vm.stock.adapters = _.filter(data, function(o) { return o.id > 3; });
        }

        function edit(user) {
            modal(user);
        }

        function add() {
            modal({});
        }

        function modal(data) {
            $uibModal.open({
                templateUrl: 'stockEdit.html',
                controller: 'StockEditCtrl',
                controllerAs: 'StockEditVm',
                size: 'md',
                resolve: {
                    data: function() { return data; }
                }
            }).result.then(function(device) {
                Stock.update(device).then(function(response) {
                    initTables(response);
                });
            });
        }

    }

})();
