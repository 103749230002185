(function () {
    'use strict';

    angular.module('app.factories')
        .factory('UserIndex', UserIndex);

    UserIndex.$inject = ['APIService', '$http', '$q', 'BASE_URL'];

    function UserIndex(APIService, $http, $q, BASE_URL) {
        var service = new APIService(BASE_URL+'/api/user_api/index');

        return angular.extend(service, {
            ping: function() {
                var deferred = $q.defer();

                $http.get(BASE_URL+'/api/user_api/index/ping').then(function(response) {
                    deferred.resolve(response.data.data);
                }, function (response) {
                    deferred.reject(response);
                });

                return deferred.promise;
            }
        });
    }

})();
